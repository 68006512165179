import axios /*, { post }*/ from "axios";
import { saveAs } from "file-saver";
import { Constants, paymentScheme } from "./index";
import moment from "moment";

// import {u_atob, u_btoa,s2ab} from "./api"

class HafServices {
  register = (newUser, setupOrg, promoRegistr) => {
    console.log("setupOrg=", setupOrg);
    return axios
      .post("users/register", {
        firstname: newUser.firstname,
        // middlename: newUser.middlename,
        lastname: newUser.lastname,
        organization: newUser.organization,
        email: newUser.email_onblur,
        password: newUser.password,
        confirm: newUser.confirm,
        country: setupOrg.country,
        phone: setupOrg.phone,
        version_word: "Desktop version",
        degree: newUser.degree,
        prof_credential: newUser.prof_credential,
        promo_registr: promoRegistr,
        referral_parent: setupOrg.referral_parent,
        sms_note: setupOrg.sms_note,
      })
      .then((res) => {
        return res.data;
      });
  };

  verifyAcc = (activationInfo) => {
    return axios
      .get(
        `/users/verify?email=${activationInfo.email}&id=${activationInfo.hashCode}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  registernext = (newUser, setupOrg) => {
    // console.log("newUser=",newUser,"setupOrg=",setupOrg)
    return axios
      .post("users/registernext", {
        firstname: newUser.firstname,
        // middlename: newUser.middlename,
        lastname: newUser.lastname,
        CompanyId: setupOrg.id,
        // email: newUser.email,
        email: newUser.email_onblur,
        role: newUser.role,
        version_word: newUser.version_word,
        degree: newUser.degree,
        prof_credential: newUser.prof_credential,
        password: newUser.password,
        confirm: newUser.confirm,
        organization: setupOrg.organization,
      })
      .then((res) => {
        return res.data;
      });
  };

  login = (user) => {
    return axios
      .post("users/login", {
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        // console.log("res.data.token=",res.data.token)
        if (!res.data.error) localStorage.setItem("usertoken", res.data.token);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  //write from table
  updateUser = (arr, id) => {
    return axios
      .put(
        "/api/user/" + id,
        {
          firstname: arr.firstname,
          // middlename: arr.middlename,
          lastname: arr.lastname,
          CompanyId: arr.CompanyId,
          // email: arr.email,
          email: arr.email_onblur,
          role: arr.role,
          version_word: arr.version_word,
          degree: arr.degree,
          prof_credential: arr.prof_credential,
          // password: arr.password,
          // confirm: arr.confirm
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  resetPasswordField = (newUser, passwordForReset) => {
    // console.log("newUser=",newUser,"passwordForReset=",passwordForReset);
    return axios
      .put(
        "/users/resetpasswordfield/" + newUser.id,
        {
          password: passwordForReset.password,
          confirm: passwordForReset.confirm,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  getUserInfo = (id) => {
    return axios
      .get(`/api/getuserinfo?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  /*
  const fileUpload =(file) => {
      // const fd = new FormData();
      // fd.append ('image',file, file.name)
      // console.log(">>>>>>>",fd)
      console.log(file)
      let fd = new FormData();
      fd.append('image', file);
    // fd.append('name', file.name);
      console.log(fd);
       return(dispatch) => {
          axios.post('/files',fd)
          axios.post('/files',fd)
          .then(response => dispatch({
              type: 'UPLOAD_DOCUMENT_SUCCESS',
              response
          }))
          .catch(error => dispatch({
              type: 'UPLOAD_DOCUMENT_FAIL',
              error
          }))
       }
  }
  */

  // fileUpload = (file) => {
  //     const url = '/files';
  //     const formData = new FormData();
  //     formData.append('file',file)
  //     const config = {
  //         headers: {
  //             'content-type': 'multipart/form-data'
  //         }
  //     }
  //     return  post(url, formData,config)
  //   }


  /*
  function fileUpload(file) {
    fetch('/files', {
      // content-type header should not be specified!
      method: 'POST',
      body: file,
    })
      .then(response => response.json())
      .then(success => {
        // Do something with the successful response
      })
      .catch(error => console.log(error)
    );
  }
  */
  // fileUpload = (dispatch, description, selectedFile) => {
  //   let formData = new FormData();
  //   formData.append("UploadLogo", description);
  //   formData.append("UploadLogo", selectedFile);
  //   axios
  //     .post("/api/upload", formData)
  //     .then((response) => {
  //       console.log("response=", response);
  //       // if(!response.data.error)
  //       dispatch({
  //         type: "UPLOAD_DOCUMENT_SUCCESS",
  //         payload: response.data.msg.filename,
  //       });
  //     })
  //     .catch((error) =>
  //       dispatch({
  //         type: "UPLOAD_DOCUMENT_FAIL",
  //         error,
  //       })
  //     );
  // };



  fileUpload = (description, selectedFile) => {
    let formData = new FormData();
    console.log("description=",description);
    formData.append("UploadFile", description,selectedFile);


    // fetch('http://192.168.163.30:5000/api/upload', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json'}
    // });

    // axios({
    //   method: "POST",
    //   url: "/api/upload",
    //   data: formData,
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // })

    // return axios
    //   .post({"http://192.168.163.30:5000/api/upload", formData,headers: {
    //     "Content-Type": "multipart/form-data"
    //   })
     axios
      .post("/upload", formData)
      .then((response) => {
        console.log("response=", response);
        return  response.data.msg.filename;
      })
      .catch((error) =>{
        return  error;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================

  addToCompany = (arr) => {
    console.log("addToCompany email=", arr.email);
    return axios
      .post(
        "/api/company",
        {
          organization: arr.organization,
          contact_name: arr.contactName,
          address_line_1: arr.addressLine1,
          address_line_2: arr.addressLine2,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zipCode,
          email: arr.email,
          country: arr.country,
          phone: arr.phone,
          foto: arr.foto.name,
          foto_trg: arr.foto_trg,
          referral: arr.referral,
          referral_parent: arr.referral_parent,
          sms_note: arr.sms_note,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updateCompany = (arr, id) => {
    console.log("updateCompany =", arr, "id=", id);
    return axios
      .put(
        "/api/company/" + id,
        {
          organization: arr.organization,
          contact_name: arr.contact_name,
          address_line_1: arr.address_line_1,
          address_line_2: arr.address_line_2,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          email: arr.email,
          country: arr.country,
          phone: arr.phone,
          foto: arr.foto.name,
          foto_trg: arr.foto_trg,
          referral: arr.referral,
          referral_parent: arr.referral_parent,
          sms_note: arr.sms_note,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updateCompanyReferral = (id) => {
    console.log("id=", id);
    return axios
      .put(
        "/api/companyreferral/" + id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================

  getListCompany = () => {
    return axios
      .get("/api/companies", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  getListUsers = (CompanyId) => {
    return axios
      .get("/api/users?CompanyId=" + CompanyId, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getCompanyInfo = (id) => {
    return axios
      .get("/api/company/" + id, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  //Read to form
  putUsers = (arr, id) => {
    console.log("putUsers email=", arr.email);
    return axios
      .put(
        "/api/user/" + id,
        {
          firstname: arr.firstname,
          // middlename: arr.middlename,
          lastname: arr.lastname,
          email: arr.email,
          password: arr.password,
          created: arr.created,
          role: arr.role,
          status: arr.status,
          attempts: arr.attempts,
          showgettingstarted: arr.showgettingstarted,
          showtour: arr.showtour,
          verifycode: arr.verifycode,
          version_word: arr.version_word,
          degree: arr.degree,
          prof_credential: arr.prof_credential,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  deleteUser = (id) => {
    console.log("id=", id);
    return axios
      .put(
        "/api/deleteuser/" + id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  resetUser = (arr) => {
    return axios
      .post("users/forgetpass", {
        email: arr.email_onblur,
      })
      .then((res) => {
        return res.data;
      });
  };
  //-----------------------------------------------------HISTORY-------------------
  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================

  getListHistories = (CompanyId, Offset, Limit) => {
    return axios
      .get(
        `/api/histories?CompanyId=${CompanyId}&UserId=*&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================
  getListHistoriesCount = (CompanyId) => {
    return axios
      .get(`/api/historiescount?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  addEventToHistory = (msg, CompanyId, UserId) => {
    return axios
      .post(
        `/api/history?CompanyId=${CompanyId}&UserId=${UserId}`,
        {
          CompanyId: CompanyId,
          UserId: UserId,
          event: msg,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //-------------------------------------------------------------------
  getListCreditsCommon = (CompanyId, type_system) => {
    return axios
      .get(
        `/api/creditscommon?CompanyId=${CompanyId}&type_system=${type_system}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res.data=",res.data);
        return res.data;
      });
  };

  getListCredits = (CompanyId, Stat, Offset, Limit) => {
    return axios
      .get(
        `/api/credits?CompanyId=${CompanyId}&UserId=*&exp=${Stat[0]}&rec=${Stat[1]}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiQ29tcGFueUlkIjoxLCJlbWFpbCI6Im1rb250c2V2b3lAZ21haWwuY29tIiwiaWF0IjoxNTc2NzkwOTgyLCJleHAiOjE1NzY4MDUzODJ9.YvxQnV-lNJzE_GiG7EKpmAQp4VR7VxAj8sBw8VItBko"
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res.data=",res.data);
        return res.data;
      });
  };

  getListCreditsCount = (CompanyId, Stat) => {
    return axios
      .get(
        // `/api/creditscount?CompanyId=${CompanyId}&exp=${Stat[0]}&rec=${Stat[1]}`,
        `/api/creditscount?CompanyId=2&exp=true&rec=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log(">>>>>>>>>>>>>>>>res.data=",res.data)
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                            REFERRAL CHECK AND TRANSACTION                            *
   *                                                                                      */
  //========================================================================================

  referralTransaction = (CompanyId, UserId, newCredit) => {
    // console.log("arr=",JSON.stringify(arr.payment_json))
    console.log(
      "CompanyId=",
      CompanyId,
      "UserId=",
      UserId,
      "newCredit.referral_parent=",
      newCredit.referral_parent
    );
    return axios
      .post(
        `/api/referraltransaction?CompanyId=${CompanyId}&UserId=${UserId}`,
        {
          // CompanyId: arr.CompanyId,
          // UserId: arr.UserId,
          // incoming: arr.incoming,
          // outcoming: 0,
          // total_sum: arr.total_sum,
          course: newCredit.course,
          // description: arr.description,
          // status: arr.status,
          // payment_json: JSON.stringify(arr.payment_json),
          referral_parent: newCredit.referral_parent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                     POST INCOMING                                    *
   *                                                                                      */
  //========================================================================================

  postIncomingTransaction = (arr) => {
    // console.log("arr=",JSON.stringify(arr.payment_json))
    // console.log("arr=",arr)
    return axios
      .post(
        `/api/creditspaypal?CompanyId=${arr.CompanyId}&UserId=${arr.UserId}`,
        {
          CompanyId: arr.CompanyId,
          UserId: arr.UserId,
          incoming: arr.incoming,
          outcoming: 0,
          // credit_date: "?",
          total_sum: arr.total_sum,
          course: arr.course,
          /*
          address_city: arr.address_city,
          address_country_code: arr.address_country_code,
          address_line1: arr.address_line1,
          address_postal_code: arr.address_postal_code,
          address_recipient_name: arr.address_recipient_name,
          address_state: arr.address_state,
          paypal_email: arr.paypal_email,
          payerID: arr.payerID,
          paymentID: arr.paymentID,
          paymentToken: arr.paymentToken,
          returnUrl: arr.returnUrl,
          description: arr.description,
*/
          description: arr.description,
          status: arr.status,
          payment_json: JSON.stringify(arr.payment_json),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                    POST OUTCOMING                                    *
   *                                                                                      */
  //========================================================================================

  postOutcomingTransaction = (arr) => {
    return axios
      .post(
        `/api/creditspaypal?CompanyId=${arr.CompanyId}&UserId=${arr.UserId}`,
        {
          CompanyId: arr.CompanyId,
          UserId: arr.UserId,
          incoming: 0,
          outcoming: arr.outcoming,
          // credit_date: "?",
          total_sum: arr.total_sum,
          course: arr.course,
          description: arr.description,
          status: arr.status,
          payment_json: arr.payment_json,
          /*
          address_city: arr.address_city,
          address_country_code: arr.address_country_code,
          address_line1: arr.address_line1,
          address_postal_code: arr.address_postal_code,
          address_recipient_name: arr.address_recipient_name,
          address_state: arr.address_state,
          paypal_email: arr.paypal_email,
          payerID: arr.payerID,
          paymentID: arr.paymentID,
          paymentToken: arr.paymentToken,
          returnUrl: arr.returnUrl,
          description: arr.description
*/
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getUserRecordByEmail = (email) => {
    return axios
      .get(`/api/getuserrecordbyemail?email=${email}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                    getCountOutcoming                                   *
   *                                                                                      */
  //========================================================================================

  getCountOutcoming = (
    CompanyId,
    subscription_start_date,
    subscription_end_date
  ) => {
    return axios
      .get(
        `/api/getcountoutcoming?CompanyId=${CompanyId}&subscription_start_date=${subscription_start_date}&subscription_end_date=${subscription_end_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                    SEARCH SUBSCRIPTION                                    *
   *                                                                                      */
  //========================================================================================

  searchSubsription = (
    CompanyId,
    UserId,
    subscriptionId,
    subscription_start_date,
    subscription_end_date
  ) => {
    return axios
      .get(
        `/api/searchsubscription?CompanyId=${CompanyId}&UserId=${UserId}&subscriptonId=${subscriptionId}&subscription_start_date=${subscription_start_date}&subscription_end_date=${subscription_end_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                    SEARCH SUBSCRIPTION By Period                                    *
   *                                                                                      */
  //========================================================================================

  searchSubsriptionByPeriod = (CompanyId, type_system) => {
    // console.log("Stat=",Stat);
    // console.log("type_system=",type_system);
    return axios
      .get(
        `/api/searchsubscriptionbyperiod?CompanyId=${CompanyId}&type_system=${type_system}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                    POST SUBSCRIPTION                                    *
   *                                                                                      */
  //========================================================================================
  // postSubscriptionTransaction = (arr) => {
  //   // console.log("arr=",JSON.stringify(arr.payment_json))
  //   // console.log("arr=",arr)
  //   return axios
  //     .post(
  //       `/api/creditssubscription?CompanyId=${arr.CompanyId}&UserId=${arr.UserId}`,
  //       {
  //         CompanyId: arr.CompanyId,
  //         UserId: arr.UserId,
  //         incoming: 0,
  //         outcoming: 0,
  //         // credit_date: "?",
  //         total_sum: paymentScheme.subscriptionPrice,
  //         course: 1,
  //         subscription_start_date: arr.subscription_start_date,
  //         subscription_end_date: arr.subscription_end_date,
  //         subscription_status: arr.subscription_status,
  //         description: arr.customer,
  //         status: arr.subscriptionId,
  //         // payment_json: JSON.stringify(arr.payment_json),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       return res.data;
  //     });
  // };

  postSubscriptionTransaction = (arr) => {
    return axios
      .post(
        `/api/creditssubscription?CompanyId=${arr.CompanyId}&UserId=${arr.UserId}`,
        {
          CompanyId: arr.CompanyId,
          UserId: arr.UserId,
          incoming: arr.credit,
          outcoming: 0,
          // credit_date: "?",
          total_sum: arr.cost /*paymentScheme.subscriptionPrice*/,
          course: 1,
          subscription_start_date: arr.subscription_start_date,
          subscription_end_date: arr.subscription_end_date,
          subscription_status: arr.subscription_status,
          type_system: arr.type_system,
          product: arr.product,
          stripe_price_id: arr.stripe_price_id,
          description: arr.customer,
          status: arr.subscriptionId,
          // payment_json: JSON.stringify(arr.payment_json),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                    POST SUBSCRIPTION                                    *
   *                                                                                      */
  //========================================================================================

  putSubscriptionTransaction = (arr, subscriptionId) => {
    console.log("putSubscriptionTransaction arr=", arr);
    return axios
      .put(
        `/api/putcreditssubscription/` + subscriptionId,
        {
          subscription_status: arr.subscription_status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  // updateUser = (arr, id) => {
  //   return axios
  //     .put(
  //       "/api/user/" + id,
  //       {
  //         firstname: arr.firstname,
  //         // middlename: arr.middlename,
  //         lastname: arr.lastname,
  //         CompanyId: arr.CompanyId,
  //         // email: arr.email,
  //         email: arr.email_onblur,
  //         role: arr.role,
  //         version_word: arr.version_word,
  //         degree: arr.degree,
  //         prof_credential : arr.prof_credential
  //         // password: arr.password,
  //         // confirm: arr.confirm
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then(function (res) {
  //       return res.data;
  //     });
  // };

  getIds = (email) => {
    return axios
      .get(`/api/getidusers?email=${email}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getSumIncoming = (CompanyId) => {
    return axios
      .get(`/api/creditssuminc?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getSumOutcoming = (CompanyId) => {
    return axios
      .get(`/api/creditssumout?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getTotalSum = (CompanyId) => {
    return axios
      .get(`/api/creditssumtotalsum?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getMessages = (CompanyId, FromUserId, ToUserId, Offset, Limit) => {
    return axios
      .get(
        `/api/messages?CompanyId=${CompanyId}&FromUserId=${FromUserId}&ToUserId=${ToUserId}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  getStatusMessagesCount = (CompanyId, ToUserId, status) => {
    return axios
      .get(
        `/api/messagescount?CompanyId=${CompanyId}&ToUserId=${ToUserId}&status=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  addMessage = (msg, CompanyId, FromUserId, ToUserId) => {
    return axios
      .post(
        `/api/message?CompanyId=${CompanyId}&FromUserId=${FromUserId}&ToUserId=${ToUserId}`,
        {
          CompanyId: CompanyId,
          FromUserId: FromUserId,
          ToUserId: ToUserId,
          msg: msg,
          status: "new",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  updateMessageStatus = (id, status) => {
    return axios
      .put(
        `/api/message?id=${id}&status=${status}`,
        {
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                       QUANTITY ASSESSMENT FOR COMPANY AND USER                       *
   *                                                                                      */
  //========================================================================================
  getListMyAssessmentsCountId = (CompanyId, UserId) => {
    // console.log("CompanyId=",CompanyId,"UserId=",UserId)
    return axios
      .get(
        `/api/myassessmentscountid?CompanyId=${CompanyId}&UserId=${UserId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                   CHECK ASSESSMENT id                                   *
   *                                                                                      */
  //========================================================================================

  testAssessmentId = (newAssessment) => {
    return axios
      .get(
        `/api/testassessmentid?AssessmentId=${newAssessment.assessment}&id=${newAssessment.id}&UserId=${newAssessment.UserId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  checkAssessmentBeforeAdd = (arr, CompanyId, UserId) => {
    return axios
      .post(
        `/api/checkassessmentbeforeadd?CompanyId=${CompanyId}&UserId=${UserId}`,
        {
          CompanyId: CompanyId,
          UserId: UserId,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          // email_client: arr.email_client,
          email_client: arr.email_client_onblur,
          assessment: arr.assessment,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          provider: arr.provider,
          // email: arr.email
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //       First set version
          // version: Constants.VERSION,
          version:
            arr.adult_type === "Adolescent"
              ? Constants.VER_ADOLESCENT
              : arr.adult_type === "Adult"
              ? Constants.VER_ADULT
              : arr.adult_type === "ASI" || arr.adult_type === "ASILite"
              ? Constants.VER_ASI
              : Constants.VER_ADOLESCENT,
          // version_update: Constants.VERSION_UPDATE_DATA
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                  ADD NEW ASSESSMENT                                  *
   *                                                                                      */
  //========================================================================================

  addAssessment = (arr, CompanyId, UserId) => {
    // console.log("arr addAssessment=",arr);
    return axios
      .post(
        `/api/assessment?CompanyId=${CompanyId}&UserId=${UserId}`,
        {
          CompanyId: CompanyId,
          UserId: UserId,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          // email_client: arr.email_client,
          email_client: arr.email_client_onblur,
          assessment: arr.assessment,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          provider: arr.provider,
          // email: arr.email
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //       First set version
          // version: Constants.VERSION,
          version:
            arr.adult_type === "Adolescent"
              ? Constants.VER_ADOLESCENT
              : arr.adult_type === "Adult"
              ? Constants.VER_ADULT
              : arr.adult_type === "ASI" || arr.adult_type === "ASILite"
              ? Constants.VER_ASI
              : Constants.VER_ADOLESCENT,
          // version_update: Constants.VERSION_UPDATE_DATA
          payment_method: arr.payment_method,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                 GET ASSESSMENT BY ID                                 *
   *                                                                                      */
  //========================================================================================

  checkAssessment = (id) => {
    return axios
      .get(`/api/checkassessment?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                 GET ASSESSMENT BY HAFCode                                 *
   *                                                                                      */
  //========================================================================================

  getAssessment = (hafCode) => {
    return axios
      .get(`/api/getassessment?hafCode=${hafCode}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                 Update print status                                  *
   *                                                                                      */
  //========================================================================================
  /*
putPrintStatus = (arr) => {
  return axios
    .put(
      "/api/assessmentprintstatus/" + arr.id,
      {
        assessment: arr.assessment,
        firstname: arr.firstname,
        middlename: arr.middlename,
        lastname: arr.lastname,
        gender:arr.gender,
        sex: arr.sex,
        gender_explain: arr.gender_explain,
        marriage:arr.marriage,
        email_client: arr.email_client,
        street: arr.street,
        city: arr.city,
        state: arr.state,
        zip_code: arr.zip_code,
        country: arr.country,
        county:arr.county,
        evaluator: arr.evaluator,
        degree_prof_credential: arr.degree_prof_credential,
        phone: arr.phone,
        ssn: arr.ssn,
        dob: arr.dob,
        updated: new Date(),
        provider: arr.provider,
        status:arr.status,
        atype: arr.atype,
        allow_support: arr.allow_support,
        print_status: arr.print_status,
        verifycode:arr.verifycode,
        remotestatus:arr.remotestatus
        //when edit doc do not change version
        // version: arr.version,
        // version_update: arr.version_update
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then(res => {
      return res.data;
    });
};
*/
  //========================================================================================
  /*                                                                                      *
   *                                 CHECK JSON ORIGINAL                                *
   *                                                                                      */
  //========================================================================================
  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================

  checkJsonOriginal = (id) => {
    return axios
      .get(`/api/checkassessmentjsonoriginal?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                    PUT ASSESSMENT                                    *
   *                                                                                      */
  //========================================================================================

  putAssessment = (arr) => {
    // console.log("putAssessment=",arr);
    return axios
      .put(
        "/api/assessment/" + arr.id,
        {
          CompanyId: arr.CompanyId,
          UserId: arr.UserId,
          assessment: arr.assessment,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          // email_client: arr.email_client,
          email_client: arr.email_client_onblur,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          // created: arr.created,
          updated: new Date(),
          provider: arr.provider,
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //when edit doc do not change version
          version: arr.version,
          // version_update: arr.version_update
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  putAssessmentWithoutCheck = (arr) => {
    // console.log("putAssessment");
    return axios
      .put(
        "/api/assessmentwithoutcheck/" + arr.id,
        {
          CompanyId: arr.CompanyId,
          UserId: arr.UserId,
          assessment: arr.assessment,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          // email_client: arr.email_client,
          email_client: arr.email_client_onblur,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          // created: arr.created,
          updated: new Date(),
          provider: arr.provider,
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //when edit doc do not change version
          version: arr.version,
          // version_update: arr.version_update
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                 PUT ASSESSMENT DETAIL                                *
   *                                                                                      */
  //========================================================================================
  putAssessmentDetail = (arr, id, status) => {
    // console.log("status=",status)
    const assessmentJson = JSON.stringify(arr);
    return axios
      .put(
        "/api/assessmentjson/" + id,
        {
          status: status,
          updated: new Date(),
          assessment_json: assessmentJson,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                 PUT JSON TO ORIGINAL                                 *
   *                                                                                      */
  //========================================================================================
  putAssessmentDetailToOriginal = (arr, id) => {
    // console.log("status=",status)
    const assessmentJson = JSON.stringify(arr);
    return axios
      .put(
        "/api/assessmentjsonoriginal/" + id,
        {
          assessment_json: assessmentJson,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                CHECK ASSESSMENT BEFORE UPGRADE                       *
   *                                                                                      */
  //========================================================================================
  //========================================================================================
  /*                                                                                      *
   *                                        NOT USE                                       *
   *                                                                                      */
  //========================================================================================

  checkAssessmentBeforeUpgrade = (arr) => {
    console.log("checkAssessmentBeforeUpgrade");
    return axios
      .put(
        "/api/checkbeforeupgrade/" + arr.id,
        {
          assessment: arr.assessment,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          // email_client: arr.email_client,
          email_client: arr.email_client_onblur,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          // updated: new Date(),
          provider: arr.provider,
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //when edit doc do not change version
          version:
            arr.adult_type === "Adolescent"
              ? Constants.VER_ADOLESCENT
              : arr.adult_type === "Adult"
              ? Constants.VER_ADULT
              : arr.adult_type === "ASI" || arr.adult_type === "ASILite"
              ? Constants.VER_ASI
              : Constants.VER_ADOLESCENT,
          // version: Constants.VERSION,
          version_update: new Date(),
          payment_method: arr.payment_method,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                PUT ASSESSMENT UPGRADE                                *
   *                                                                                      */
  //========================================================================================
  putAssessmentUpgrade = (arr) => {
    // console.log("arr=",arr);
    return axios
      .put(
        "/api/assessmentupgrade/" + arr.id,
        {
          assessment: arr.assessment,
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          gender: arr.gender,
          sex: arr.sex,
          gender_explain: arr.gender_explain,
          marriage: arr.marriage,
          email_client: arr.email_client_onblur,
          // email_client: arr.email_client_onblur,
          street: arr.street,
          city: arr.city,
          state: arr.state,
          zip_code: arr.zip_code,
          country: arr.country,
          county: arr.county,
          evaluator: arr.evaluator,
          degree_prof_credential: arr.degree_prof_credential,
          phone: arr.phone,
          ssn: arr.ssn || "000000000",
          dob: arr.dob,
          updated: new Date(),
          provider: arr.provider,
          status: arr.status,
          adult_type: arr.adult_type,
          atype: arr.atype,
          allow_support: arr.allow_support,
          print_status: arr.print_status,
          verifycode: arr.verifycode,
          remotestatus: arr.remotestatus,
          //when edit doc do not change version
          version:
            arr.adult_type === "Adolescent"
              ? Constants.VER_ADOLESCENT
              : arr.adult_type === "Adult"
              ? Constants.VER_ADULT
              : arr.adult_type === "ASI" || arr.adult_type === "ASILite"
              ? Constants.VER_ASI
              : Constants.VER_ADOLESCENT,
          // version: Constants.VERSION,
          version_update: new Date(),
          payment_method: arr.payment_method,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                   LIST WHEN SEARCH                                   *
   *                                                                                      */
  //========================================================================================

  getSearchMyAssessmentsStat = (
    CompanyId,
    UserId,
    Field,
    Search,
    promoId,
    adultType,
    Stat,
    Period,
    Offset,
    Limit
  ) => {
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    // console.log(`/api/searchmyassessmentsstat?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}&Period=${Period}&Offset=${Offset}&Limit=${Limit}`);
    // console.log(Period);
    // console.log(fromDate);
    return axios
      .get(
        `/api/searchmyassessmentsstat?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&promoId=${promoId}&adultType=${adultType}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                   COUNT WHEN SEARCH                                  *
   *                                                                                      */
  //========================================================================================

  getListMyAssessmentsCountSearchStat = (
    CompanyId,
    UserId,
    Field,
    Search,
    promoId,
    adultType,
    Stat,
    Period
  ) => {
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    // console.log(`/api/myassessmentscountsearchstat?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}`);
    return axios
      .get(
        `/api/myassessmentscountsearchstat?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&promoId=${promoId}&adultType=${adultType}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}&Period=${fromDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListMyAssessmentsCountSearchStatType = (
    CompanyId,
    UserId,
    Field,
    Search,
    promoId,
    adultType,
    Stat,
    Period,
    Type
  ) => {
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    // console.log(`/api/myassessmentscountsearchstat?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}`);
    return axios
      .get(
        `/api/myassessmentscountsearchstattype?CompanyId=${CompanyId}&UserId=${UserId}&field=${Field}&search=${Search}&promoId=${promoId}&adultType=${adultType}&new=${Stat[0]}&ncom=${Stat[1]}&com=${Stat[2]}&Period=${fromDate}&Type=${Type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                  GENERATE NARRATIVE                                  *
   *                                                                                      */
  //========================================================================================

  generateNarratives = (arr) => {
    // console.log("arr=",arr);
    return axios
      .put(
        "/api/narrative/" + arr.id,
        {
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          status: arr.status,
        },
        {
          headers: {
            "Content-Type":
              "Content-Type','application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(
          blob,
          `narrative_${arr.firstname}_${arr.middlename}_${arr.lastname}.rtf`
        );
        return res;
      });
  };
  generateNarrativesPdf = (arr) => {
    // console.log("arr=",arr);
    return axios
      .put(
        "/api/narrativepdf/" + arr.id,
        {
          firstname: arr.firstname,
          middlename: arr.middlename,
          lastname: arr.lastname,
          status: arr.status,
        },
        {
          headers: {
            "Content-Type": "Content-Type','application/pdf",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        saveAs(
          blob,
          `narrative_${arr.firstname}_${arr.middlename}_${arr.lastname}.pdf`
        );
        return res;
      });
  };
  /*
generateNarrativesDocx = (arr,setupUser) => {
  console.log("setupUser.firstname=",setupUser.firstname,"setupUser.lastname=",setupUser.lastname);
  return axios.put(
    "/api/narrativedocx/" + arr.id,
    {
      firstnameCounselor: setupUser.firstname,
      lastnameCounselor: setupUser.lastname,
      firstname: arr.firstname,
      middlename: setupUser.middlename,
      lastname: arr.lastname,
      status:arr.status
    },
    {
      headers: {
        // "Content-Type": "application/octet-stream",
        // "Content-Type": "Content-Type','application/pdf",
        // "Content-Disposition": "attachment;filename=narrative.rtf"
        "Content-Type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      }
    }
  )
  .then((res) => {
    var binary = atob(res.data)
    var array = new Uint8Array(binary.length)
    for( var i = 0; i < binary.length; i++ ) { array[i] = binary.charCodeAt(i) }
    var blob = new Blob([array])
    window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
    // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
    // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
    return res;
  });
}
*/

  // generateNarrativesDocx = (arr, setupUser, setupOrg, scope) => {
  //   // console.log("setupUser=",setupUser);
  //   return axios
  //     .get(
  //       `/api/narrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}`,
  //       {
  //         headers: {
  //           // "Content-Type": "application/octet-stream",
  //           // "Content-Type": "Content-Type','application/pdf",
  //           // "Content-Disposition": "attachment;filename=narrative.rtf"
  //           "Content-Type":
  //             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       let firstname = arr.firstname.trim();
  //       let middlename = arr.middlename.trim();
  //       let lastname = arr.lastname.trim();
  //       let assessment = arr.assessment.trim();
  //       firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
  //       middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
  //       lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

  //       const fileName =
  //         scope === "global"
  //           ? `narrative_${assessment}.docx`
  //           : `narrative_${firstname}_${middlename}_${lastname}.docx`;

  //       var binary = atob(res.data);
  //       var array = new Uint8Array(binary.length);
  //       for (var i = 0; i < binary.length; i++) {
  //         array[i] = binary.charCodeAt(i);
  //       }
  //       var blob = new Blob([array]);
  //       window.saveAs(blob, fileName);
  //       // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
  //       // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
  //       // console.log("res=",res)
  //       return res;
  //     });
  // };

  generateNarrativesDocx = (
    arr,
    setupUser,
    setupOrg,
    scope,
    jsonPattern,
    newUser
  ) => {
    // console.log("jsonPattern=",jsonPattern);
    return axios
      .post(
        // `/api/narrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}`,
        `/api/narrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${newUser.version_word}`,
        {
          assessment_json: JSON.stringify(jsonPattern),
        },
        {
          headers: {
            // "Content-Type": "application/octet-stream",
            // "Content-Type": "Content-Type','application/pdf",
            // "Content-Disposition": "attachment;filename=narrative.rtf"
            "Content-Type": "application/json",
            // "Content-Type":
            //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        let firstname = arr.firstname.trim();
        let middlename = arr.middlename.trim();
        let lastname = arr.lastname.trim();
        let assessment = arr.assessment.trim();
        firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
        middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
        lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

        const fileName =
          scope === "global"
            ? `narrative_${assessment}.docx`
            : `narrative_${firstname}_${middlename}_${lastname}.docx`;

        var binary = atob(res.data);
        var array = new Uint8Array(binary.length);
        for (var i = 0; i < binary.length; i++) {
          array[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([array]);
        window.saveAs(blob, fileName);
        // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
        // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
        // console.log("res=",res)
        return res;
      });
  };
  generateTreatmentPlanDocx = (
    arr,
    setupUser,
    setupOrg,
    scope,
    jsonPattern,
    newUser,
    htmlPreview
  ) => {
    // console.log("jsonPattern=",jsonPattern);
    return axios
      .post(
        // `/api/narrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}`,
        `/api/treatmentplandocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${newUser.version_word}`,
        {
          assessment_json: JSON.stringify(jsonPattern),
          htmlPreview: htmlPreview,
          CompanyId: setupUser.CompanyId
        },
        {
          headers: {
            // "Content-Type": "application/octet-stream",
            // "Content-Type": "Content-Type','application/pdf",
            // "Content-Disposition": "attachment;filename=narrative.rtf"
            "Content-Type": "application/json",
            // "Content-Type":
            //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        let firstname = arr.firstname.trim();
        let middlename = arr.middlename.trim();
        let lastname = arr.lastname.trim();
        let assessment = arr.assessment.trim();
        firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
        middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
        lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

        const fileName =
          scope === "global"
            ? `treatment_plan_${assessment}.docx`
            : `treatment_plan_${firstname}_${middlename}_${lastname}.docx`;

        var binary = atob(res.data);
        var array = new Uint8Array(binary.length);
        for (var i = 0; i < binary.length; i++) {
          array[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([array]);
        window.saveAs(blob, fileName);
        // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
        // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
        // console.log("res=",res)
        return res;
      });
  };

  generateAsiDocx = (
    arr,
    setupUser,
    setupOrg,
    scope,
    asiOrAsilite,
    content,
    newUser
  ) => {
    console.log("asiOrAsilite=", asiOrAsilite, "content=", content);
    switch (content.toUpperCase()) {
      case "FORM": {
        return axios
          .get(
            // `/api/asidocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}&asiorasilite=${asiOrAsilite}`,
            `/api/asidocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${newUser.version_word}&asiorasilite=${asiOrAsilite}`,
            {
              headers: {
                // "Content-Type": "application/octet-stream",
                // "Content-Type": "Content-Type','application/pdf",
                // "Content-Disposition": "attachment;filename=narrative.rtf"
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            }
          )
          .then((res) => {
            let firstname = arr.firstname.trim();
            let middlename = arr.middlename.trim();
            let lastname = arr.lastname.trim();
            let assessment = arr.assessment.trim();
            firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
            middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
            lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

            const fileName =
              scope === "global"
                ? `${asiOrAsilite}_${assessment}.docx`
                : `${asiOrAsilite}_${firstname}_${middlename}_${lastname}.docx`;

            var binary = atob(res.data);
            var array = new Uint8Array(binary.length);
            for (var i = 0; i < binary.length; i++) {
              array[i] = binary.charCodeAt(i);
            }
            var blob = new Blob([array]);
            window.saveAs(blob, fileName);
            // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
            // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
            return res;
          });
      }
      case "NARRATIVE": {
        return axios
          .get(
            // `/api/asinarrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}&asiorasilite=${asiOrAsilite}`,
            `/api/asinarrativedocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${newUser.version_word}&asiorasilite=${asiOrAsilite}`,
            {
              headers: {
                // "Content-Type": "application/octet-stream",
                // "Content-Type": "Content-Type','application/pdf",
                // "Content-Disposition": "attachment;filename=narrative.rtf"
                "Content-Type":
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            }
          )
          .then((res) => {
            let firstname = arr.firstname.trim();
            let middlename = arr.middlename.trim();
            let lastname = arr.lastname.trim();
            let assessment = arr.assessment.trim();
            firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
            middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
            lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

            const fileName =
              scope === "global"
                ? `${asiOrAsilite}_${assessment}.docx`
                : `${asiOrAsilite}_${firstname}_${middlename}_${lastname}.docx`;

            var binary = atob(res.data);
            var array = new Uint8Array(binary.length);
            for (var i = 0; i < binary.length; i++) {
              array[i] = binary.charCodeAt(i);
            }
            var blob = new Blob([array]);
            window.saveAs(blob, fileName);
            // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
            // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
            return res;
          });
      }
      default: {
      }
    }
  };

  // generateAsiNarrativeDocx = (arr, setupUser, setupOrg, scope, asiOrAsilite) => {
  //   console.log("asiOrAsilite=",asiOrAsilite);
  //   return axios
  //     .get(
  //       `/api/asidocx?id=${arr.id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&word=${setupUser.version_word}&asiorasilite=${asiOrAsilite}`,
  //       {
  //         headers: {
  //           // "Content-Type": "application/octet-stream",
  //           // "Content-Type": "Content-Type','application/pdf",
  //           // "Content-Disposition": "attachment;filename=narrative.rtf"
  //           "Content-Type":
  //             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       let firstname = arr.firstname.trim();
  //       let middlename = arr.middlename.trim();
  //       let lastname = arr.lastname.trim();
  //       let assessment = arr.assessment.trim();
  //       firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
  //       middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
  //       lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

  //       const fileName =
  //         scope === "global"
  //           ? `${asiOrAsilite}_${assessment}.docx`
  //           : `${asiOrAsilite}_${firstname}_${middlename}_${lastname}.docx`;

  //       var binary = atob(res.data);
  //       var array = new Uint8Array(binary.length);
  //       for (var i = 0; i < binary.length; i++) {
  //         array[i] = binary.charCodeAt(i);
  //       }
  //       var blob = new Blob([array]);
  //       window.saveAs(blob, fileName);
  //       // var blob = new Blob([(atob(res.data))], {type: "application/octet-stream"});
  //       // window.saveAs(blob, `narrative_${arr.firstname}_${arr.lastname}.docx`);
  //       return res;
  //     });
  // };

  // generatePreView = (id, setupUser, setupOrg, scope) => {
  //   // console.log("id=",id);
  //   return axios
  //     .get(
  //       `/api/preview?id=${id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}`,
  //       {
  //         headers: {
  //           "Content-Type": "text/html",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       // console.log("res=",res);
  //       return res;
  //     });
  // };

  getPeogress = () => {
    // console.log("id=",id);
    return axios
      .get(`/static/progress`, {
        headers: {
          "Content-Type": "text/html",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        // console.log("res=",res);
        return res;
      });
  };

  generatePreView = (
    id,
    setupUser,
    setupOrg,
    scope,
    jsonPattern,
    section = "ALL"
  ) => {
    // console.log("section=",section);
    // const assessmentJson = JSON.stringify(arr);
    return axios
      .post(
        `/api/preview?id=${id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&section=${section}`,
        {
          assessment_json: JSON.stringify(jsonPattern),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
        // {
        //   headers: {
        //     "Content-Type": "text/html",
        //     Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        //   },
        // }
      )
      .then((res) => {
        // console.log("res=",res);
        return res;
      });
  };

  generatePreViewAsi = (id, setupUser, setupOrg, scope, asiOrAsilite) => {
    // console.log("id=",id);
    return axios
      .get(
        `/api/previewasi?id=${id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&asiorasilite=${asiOrAsilite}`,
        {
          headers: {
            "Content-Type": "text/html",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res=",res);
        return res;
      });
  };

  generatePreViewAsiNarrative = (
    id,
    setupUser,
    setupOrg,
    scope,
    asiOrAsilite
  ) => {
    // console.log("id=",id);
    return axios
      .get(
        `/api/previewasinarrative?id=${id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}&asiorasilite=${asiOrAsilite}`,
        {
          headers: {
            "Content-Type": "text/html",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res=",res);
        return res;
      });
  };

  getListUsersPage = (CompanyId, Offset, Limit) => {
    return axios
      .get(
        `/api/userspage?CompanyId=${CompanyId}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListUsersCount = (CompanyId) => {
    return axios
      .get(`/api/userscount?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getIdAdmin = (CompanyId) => {
    return axios
      .get(`/api/getidadmin?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getIdAdminCount = (CompanyId) => {
    return axios
      .get(`/api/getidadmincount?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  sendEmailRemoteSession = (
    setupUser,
    assessment,
    token = "new",
    addtext = ""
  ) => {
    console.log(
      "sendEmailRemoteSession email=",
      setupUser.email,
      " ",
      assessment.email_client_onblur
    );
    return axios
      .post("/api/remotetoken", {
        email: setupUser.email,
        evalfullname: `${setupUser.firstname} ${setupUser.lastname}`,
        id: assessment.id,
        email_client: assessment.email_client_onblur,
        token,
        addtext,
      })
      .then((res) => {
        return res.data;
      });
  };

  getAssessmentByEmail = (emailClient, token) => {
    console.log(
      "getAssessmentByEmail emailClient=",
      emailClient,
      "token=",
      token
    );
    return axios
      .get(
        `/api/getassessmentbyemail?email_client=${emailClient}&token=${token}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  // putRemoteStatusByEmail = (emailClient, token, remotestatus) => {
  //   console.log("putRemoteStatusByEmail emailClient=", emailClient);
  //   return axios
  //     .put("/api/putremotestatus", {
  //       email_client: emailClient,
  //       token,
  //       remotestatus,
  //     })
  //     .then((res) => {
  //       return res.data;
  //     });
  // };

  putRemoteStatusByEmail = (emailClient, token, remotestatus) => {
    console.log("putRemoteStatusByEmail emailClient=", emailClient);
    return axios
      .put(
        "/api/putremotestatus",
        {
          email_client: emailClient,
          token,
          remotestatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  // getImpressionsByCompanyId = (CompanyId) => {
  //   return axios
  //     .get(`/api/impressions?CompanyId=${CompanyId}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //       },
  //     })
  //     .then((res) => {
  //       return res.data;
  //     });
  // };

  getImpressionsByCompanyId = (CompanyId, assessmentId, scope) => {
    return axios
      .get(
        `/api/impressions?CompanyId=${CompanyId}&assessmentId=${assessmentId}&scope=${scope}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListImpressionPage = (CompanyId, Offset, Limit) => {
    return axios
      .get(
        `/api/impressionspage?CompanyId=${CompanyId}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListImpressionsCount = (CompanyId) => {
    return axios
      .get(`/api/impressionscount?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  addImpression = (CompanyId, newImpression) => {
    console.log("addImpression CompanyId=",CompanyId);
    return axios
      .post("/api/newimpression", {
        CompanyId: CompanyId,
        shortname: newImpression.shortname,
        checkbox_options: newImpression.checkbox_options,
        problem: newImpression.problem,
      })
      .then((res) => {
        return res.data;
      });
  };

  deleteImpression = (id) => {
    return axios
      .delete("/api/impression/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  updateImpression = (CompanyId, newImpression) => {
    // console.log("newImpression=",newImpression);
    return axios
      .put(
        "/api/impression/" + newImpression.id,
        {
          CompanyId: CompanyId,
          shortname: newImpression.shortname,
          checkbox_options: newImpression.checkbox_options,
          problem: newImpression.problem,
          // password: arr.password,
          // confirm: arr.confirm
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  searchImpression = (CompanyId, shortname) => {
    return axios
      .get(
        `/api/searchimpression?CompanyId=${CompanyId}&shortname=${shortname}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updateImpressionForce = (CompanyId, newImpression) => {
    return axios
      .put(
        "/api/impressionforce/",
        {
          CompanyId: CompanyId,
          shortname: newImpression.shortname,
          checkbox_options: newImpression.checkbox_options,
          problem: newImpression.problem,
          // password: arr.password,
          // confirm: arr.confirm
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  sendConfirmationMail = (
    setupUser,
    newCredit,
    paymentMethod,
    paymentIntent
  ) => {
    // console.log("sendConfirmationMail email=", setupUser.email);
    return axios
      .post(
        "/users/sendmail",
        {
          firstname: setupUser.firstname,
          lastname: setupUser.lastname,
          firstmail: setupUser.email,
          cardholder: newCredit.cardholder,
          secondemail: newCredit.card_email,
          payment_method_id: paymentMethod.id,
          payment_card: paymentMethod.card.last4,
          amount: paymentIntent.amount,
          time_created: paymentMethod.created,
          // time:
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => console.log(error));
  };

  VerificationRepeate = (login) => {
    return axios
      .get(`/users/verificationrepeate?email=${login}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                   LIST WHEN SEARCH                                   *
   *                                                                                      */
  //========================================================================================

  getListCompanyPageQuery = (
    Field,
    Search,
    promoId,
    Period,
    Stat,
    Offset,
    Limit
  ) => {
    let fromDate = "1970-01-01";
    // console.log(`field=${Field}&search=${Search}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}`)
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        `/api/companiespagequery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&sms_yes=${Stat[0]}&sms_no=${Stat[1]}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListCompanyCountQuery = (Field, Search, promoId, Period, Stat) => {
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        `/api/companiescountquery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&sms_yes=${Stat[0]}&sms_no=${Stat[1]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res.data=",res.data);
        return res.data;
      });
  };

  getListCompanyAll = (Field, Search, Offset, Limit) => {
    return axios
      .get(
        `/api/companiesall?field=${Field}&search=${Search}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //-----------------------------------------------------CASHFLOW------------------
  getListCompanyCashflowPageQuery = (
    Field,
    Search,
    promoId,
    Period,
    Offset,
    Limit,
    Param_sort,
    Param_up_down
  ) => {
    // console.log("promoId=",promoId);
    // console.log(`param_sort=${Param_sort}&param_up_down=${Param_up_down}`)
    // let fromDate="1970-01-01";
    // console.log(`field=${Field}&search=${Search}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}`)
    // if (Period === "all") fromDate = "1970-01-01";
    // else if (Period === "today")
    //   fromDate = moment()
    //     .subtract(1, "days")
    //     .startOf("day")
    //     .format("YYYY-MM-DD");
    // else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    let fromDate = moment()
      .subtract(Constants.PERIOD_IN_DAY_FOR_CASHFLOW_ANALIZE, "days")
      .startOf("day")
      .format("YYYY-MM-DD");
    return axios
      .get(
        `/statistics/companiespagequery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}&param_sort=${Param_sort}&param_up_down=${Param_up_down}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //-----------------------------------------------------HISTORY-------------------
  getListHistoriesQuery = (
    Field,
    Search,
    SearchEmailAndId,
    SearchFrom,
    SearchTo,
    CompanyId,
    Offset,
    Limit
  ) => {
    return axios
      .get(
        `/api/historiesquery?field=${Field}&search=${Search}&SearchFrom=${SearchFrom}&SearchTo=${SearchTo}&CompanyId=${CompanyId}&UserId=${SearchEmailAndId}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListHistoriesQueryCommon = (
    Field,
    Search,
    SearchEmailAndId,
    SearchFrom,
    SearchTo,
    CompanyId,
    Offset,
    Limit
  ) => {
    return axios
      .get(
        `/api/historiesquerycommon?field=${Field}&search=${Search}&SearchFrom=${SearchFrom}&SearchTo=${SearchTo}&CompanyId=${CompanyId}&UserId=${SearchEmailAndId}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                     Authorization                                    *
   *                                                                                      */
  //========================================================================================
  getListHistoriesCountQuery = (
    Field,
    Search,
    SearchEmailAndId,
    SearchFrom,
    SearchTo,
    CompanyId
  ) => {
    return axios
      .get(
        `/api/historiescountquery?field=${Field}&search=${Search}&SearchFrom=${SearchFrom}&SearchTo=${SearchTo}&CompanyId=${CompanyId}&UserId=${SearchEmailAndId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                     Authorization                                    *
   *                                                                                      */
  //========================================================================================
  getEmailAndId = (CompanyId) => {
    return axios
      .get(`/api/getemailandid?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  deleteCompany = (id) => {
    console.log("/api/company/", id);
    return axios
      .delete("/api/company/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  sendContainerNotification = (paramNotification, setupUser) => {
    console.log(paramNotification, "=", setupUser);
    return axios
      .post("/statistics/containernotification", {
        paramNotification: paramNotification,
        setupUser: setupUser,
      })
      .then((res) => {
        console.log("res.data=", res.data);
        return res.data;
      });
  };

  /*
sendEmailSignedButNotVerified = (CompanyId, setupUser,addtext="") => {
  return axios
    .post("/statistics/signedbutnotverified", {
      from:setupUser.email,
      CompanyId:CompanyId,
      addtext
    })
    .then(res => {
      console.log("res.data=",res.data);
      return res.data;
    });
};




sendEmailNonWorkingLongTime = (CompanyId, setupUser,addtext="") => {
  return axios
    .post("/statistics/nonworkinglongtime", {
      from:setupUser.email,
      CompanyId:CompanyId,
      addtext
    })
    .then(res => {
      console.log("res.data=",res.data);
      return res.data;
    });
};

sendEmailUseFreeCreditsButStopUsing= (CompanyId, setupUser,addtext="") => {
  return axios
    .post("/statistics/usefreecreditsbutstopusing", {
      from:setupUser.email,
      CompanyId:CompanyId,
      addtext
    })
    .then(res => {
      console.log("res.data=",res.data);
      return res.data;
    });
};

*/

  getAdminsByCompany = (CompanyId) => {
    return axios
      .get(`/statistics/getadminsbycompanyid?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getCompanyByEmail = (email) => {
    console.log("getCompanyByEmail email=", email);
    return axios
      .get(`/api/getcompanybyemail?email=${email}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                     Authorization                                    *
   *                                                                                      */
  //========================================================================================

  historyLastNotification = (CompanyId) => {
    return axios
      .get(`/api/getcompanybyemail?CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  getMonthPerfAPI = (month, promoId) => {
    return axios
      .get(`/statistics/companbymonth?Period=${month}&promoId=${promoId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  getYearPerfAPI = (year, promoId) => {
    return axios
      .get(`/statistics/consolidation?Period=${year}&promoId=${promoId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getListDisorderCountQuery = (Field, Search) => {
    return axios
      .get(`/api/disorderscountquery?field=${Field}&search=${Search}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getListDisorderPageQuery = (Field, Search, Offset, Limit) => {
    return axios
      .get(
        `/api/disordersquery?field=${Field}&search=${Search}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  getDisordersTips = (Field, Search) => {
    return axios
      .get(`/api/disorderstips?field=${Field}&search=${Search}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getDisorderMass = () => {
    return axios
      .get(`/api/getdisordermass`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getListRulesPage = (adultType, Offset, Limit) => {
    // console.log("Offset=", Offset, "Limit=", Limit);
    return axios
      .get(
        `/api/rulespage?adultType=${adultType}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListRulesCount = (adultType) => {
    return axios
      .get(`/api/rulescount?adultType=${adultType}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  addRuleApi = (setupUser, newRule, ruleRecord, ruleResult, ruleName) => {
    console.log("newRule=", newRule);
    return axios
      .post("/api/newrule", {
        CompanyId: setupUser.CompanyId,
        UserId: setupUser.id,
        rule_window: ruleRecord.rule_window,
        rule_body: ruleRecord.rule_body,
        result: ruleResult,
        rulename: ruleName.trim(),
        subs: newRule.subs,
        mh: newRule.mh,
        cooc: newRule.cooc,
        adult_type: newRule.adult_type,
        priority: newRule.priority,
      })
      .then((res) => {
        return res.data;
      });
  };

  deleteRule = (id) => {
    return axios
      .delete("/api/rule/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  updateRuleApi = (setupUser, newRule, RuleRecord, ruleResult, ruleName) => {
    return axios
      .put(
        "/api/rule/" + newRule.id,
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          rule_window: RuleRecord.rule_window,
          rule_body: RuleRecord.rule_body,
          result: ruleResult,
          rulename: ruleName.trim(),
          subs: newRule.subs,
          mh: newRule.mh,
          cooc: newRule.cooc,
          adult_type: newRule.adult_type,
          priority: newRule.priority,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  runRule = (id, idRule, scope) => {
    // console.log(idRule)
    return axios
      .get(`/api/runrule?id=${id}&idrule=${idRule}&scope=${scope}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        // console.log("========res.data",res.data);
        return res;
      });
  };
  getRules = () => {
    return axios
      .get(`/api/rules`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  updateRulePriority = (ruleCheckedId) => {
    return axios
      .put(
        "/api/updaterulepriority/" + ruleCheckedId[0],
        {
          moveto: ruleCheckedId[1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  getRuleItem = (id) => {
    return axios
      .get(`/api/ruleitem?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  cloneRuleApi = (ruleRecord) => {
    // console.log("ruleRecord=",ruleRecord);
    return axios
      .post("/api/newrule", {
        CompanyId: ruleRecord.CompanyId,
        UserId: ruleRecord.UserId,
        rule_window: ruleRecord.rule_window,
        rule_body: ruleRecord.rule_body,
        result: ruleRecord.result,
        rulename: `${ruleRecord.rulename} Copy`,
        subs: ruleRecord.subs,
        mh: ruleRecord.mh,
        cooc: ruleRecord.cooc,
        adult_type: ruleRecord.adult_type,
        priority: ruleRecord.priority,
      })
      .then((res) => {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                         PROMO                                        *
   *                                                                                      */
  //========================================================================================

  addPromoApi = (setupUser, newPromo) => {
    console.log("newPromo=", newPromo);
    return axios
      .post(
        "/api/newpromo",
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          description: newPromo.description,
          code: newPromo.code,
          bonus: newPromo.bonus,
          created: newPromo.created,
          expires:
            newPromo.expires === "Unlimited"
              ? "1970-01-01T00:00:00.000Z"
              : newPromo.expires,
          status: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updatePromoApi = (setupUser, newPromo) => {
    return axios
      .put(
        "/api/newpromo/" + newPromo.id,
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          description: newPromo.description,
          code: newPromo.code,
          bonus: newPromo.bonus,
          created: newPromo.created,
          expires:
            newPromo.expires === "Unlimited"
              ? "1970-01-01T00:00:00.000Z"
              : newPromo.expires,
          status: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  getListPromosPage = (Offset, Limit, show_referral = "true") => {
    // console.log("Offset=", Offset, "Limit=", Limit);
    return axios
      .get(
        `/api/promospage?show_referral=${show_referral}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListPromosCount = () => {
    return axios
      .get(`/api/promoscount`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  deletePromo = (id) => {
    return axios
      .delete("/api/promo/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  getPromoByCompanyId = (CompanyId) => {
    return axios
      .get(`/api/promocomp?&CompanyId=${CompanyId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  updatePromocompByCompanyId = (promocomp) => {
    console.log("promocomp=", promocomp);
    return axios
      .put(
        "/api/promocomp/" + promocomp.CompanyId,
        {
          CompanyId: promocomp.CompanyId,
          PromoId: promocomp.PromoId,
          organization: promocomp.organization,
          code: promocomp.code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  //========================================================================================
  /*                                                                                      *
   *                                     Transformrule                                    *
   *                                                                                      */
  //========================================================================================

  updateTransformruleApi = (
    setupUser,
    newRule,
    RuleRecord,
    ruleResult,
    ruleName
  ) => {
    return axios
      .put(
        "/api/transformrule/" + newRule.id,
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          rule_window: RuleRecord.rule_window,
          rule_body: RuleRecord.rule_body,
          result: ruleResult,
          rulename: ruleName.trim(),
          // subs: newRule.subs,
          // mh: newRule.mh,
          // cooc: newRule.cooc,
          adult_type: newRule.adult_type,
          priority: newRule.priority,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  getListTransformrulesPage = (adultType, Offset, Limit) => {
    // console.log("adultType=",adultType,"Offset=", Offset, "Limit=", Limit);
    return axios
      .get(
        `/api/transformrulespage?adultType=${adultType}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  getListTransformrulesCount = (adultType) => {
    return axios
      .get(`/api/transformrulescount?adultType=${adultType}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  // updateRuleApi = (setupUser, newRule, RuleRecord, ruleResult, ruleName) => {
  //   return axios
  //     .put(
  //       "/api/transformrule/" + newRule.id,
  //       {
  //         CompanyId: setupUser.CompanyId,
  //         UserId: setupUser.id,
  //         rule_window: RuleRecord.rule_window,
  //         rule_body: RuleRecord.rule_body,
  //         result: ruleResult,
  //         rulename: ruleName.trim(),
  //         // subs: newRule.subs,
  //         // mh: newRule.mh,
  //         // cooc: newRule.cooc,
  //         adult_type: newRule.adult_type,
  //         priority: newRule.priority,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then(function (res) {
  //       return res.data;
  //     });
  // };
  updateTransformrulePriority = (ruleCheckedId) => {
    console.log(
      "ruleCheckedId[0]=",
      ruleCheckedId[0],
      "ruleCheckedId[1]=",
      ruleCheckedId[1]
    );
    return axios
      .put(
        "/api/updatetransformrulepriority/" + ruleCheckedId[0],
        {
          moveto: ruleCheckedId[1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };
  getTransformruleItem = (id) => {
    return axios
      .get(`/api/transformruleitem?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };
  cloneTransformruleApi = (ruleRecord) => {
    // console.log("ruleRecord=",ruleRecord);
    return axios
      .post("/api/newtransformrule", {
        CompanyId: ruleRecord.CompanyId,
        UserId: ruleRecord.UserId,
        rule_window: ruleRecord.rule_window,
        rule_body: ruleRecord.rule_body,
        result: ruleRecord.result,
        rulename: `${ruleRecord.rulename} Copy`,
        // subs: ruleRecord.subs,
        // mh: ruleRecord.mh,
        // cooc: ruleRecord.cooc,
        adult_type: ruleRecord.adult_type,
        priority: ruleRecord.priority,
      })
      .then((res) => {
        return res.data;
      });
  };

  deleteTransformrule = (id) => {
    return axios
      .delete("/api/transformrule/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  addTransformruleApi = (
    setupUser,
    newTransformrule,
    ruleRecord,
    ruleResult,
    ruleName
  ) => {
    return axios
      .post("/api/newtransformrule", {
        CompanyId: setupUser.CompanyId,
        UserId: setupUser.id,
        rule_window: ruleRecord.rule_window,
        rule_body: ruleRecord.rule_body,
        result: ruleResult,
        rulename: ruleName.trim(),
        subs: newTransformrule.subs,
        mh: newTransformrule.mh,
        cooc: newTransformrule.cooc,
        adult_type: newTransformrule.adult_type,
        priority: newTransformrule.priority,
      })
      .then((res) => {
        return res.data;
      });
  };

  checkExistingAsi = (id) => {
    return axios
      .get(`/api/checkexistingasi?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  preReferralCheck = (newCredit, setupUser) => {
    return axios
      .get(
        `/api/prereferralcheck?CompanyId=${setupUser.CompanyId}&UserId=${setupUser.id}&referral_parent=${newCredit.referral_parent}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  // createCheckoutSession=() => {
  //   return axios
  //     .post("/api/create-checkout-session", {
  //     })
  //     .then((res) => {
  //       return res.data;
  //     });
  // }

  updateReferralParent = (setupUser, newCredit) => {
    return axios
      .put(
        "/api/update_referral_parent/" + setupUser.CompanyId,
        {
          referral_parent: newCredit.referral_parent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  getListProductsPage = (Offset, Limit) => {
    return axios
      .get(`/api/productspage?&Offset=${Offset}&Limit=${Limit}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  getListProductsCount = () => {
    return axios
      .get(`/api/productscount`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  deletePproduct = (id) => {
    return axios
      .delete("/api/pproduct/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  addProductApi = (setupUser, newProduct) => {
    console.log("newProduct=", newProduct);
    return axios
      .post(
        "/api/newproduct",
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          stripe_price_id: newProduct.stripe_price_id,
          product: newProduct.product,
          credit: newProduct.credit,
          cost: newProduct.cost,
          type_system: newProduct.type_system,
          type_subscription: newProduct.type_subscription,
          status: "active",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updateProductApi = (setupUser, newProduct) => {
    console.log("updateProductApi");
    return axios
      .put(
        "/api/newproduct/" + newProduct.id,
        {
          CompanyId: setupUser.CompanyId,
          UserId: setupUser.id,
          stripe_price_id: newProduct.stripe_price_id,
          product: newProduct.product,
          credit: newProduct.credit,
          cost: newProduct.cost,
          type_system: newProduct.type_system,
          type_subscription: newProduct.type_subscription,
          status: "active",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  deleteProduct = (id) => {
    console.log("id=", id);
    return axios
      .put(
        "/api/deleteproduct/" + id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  setStatusProduct = (id, status) => {
    return axios
      .put(
        "/api/setstatusproduct/" + id,
        {
          status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  getProductByStripePriceId = (stripe_price_id) => {
    return axios
      .get(`/api/productbystripepriceid?&stripe_price_id=${stripe_price_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                   LIST Subscriptional Company WHEN SEARCH                                   *
   *                                                                                      */
  //========================================================================================

  // type_system
  getListSubsCompanyPageQuery = (
    Field,
    Search,
    promoId,
    Period,
    type_system,
    Offset,
    Limit
  ) => {
    // console.log("getListSubsCompanyPageQuery type_system=",type_system)
    let fromDate = "1970-01-01";
    // console.log(`field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&type_system=${type_system}&Offset=${Offset}&Limit=${Limit}`)
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        `/api/companiessubspagequery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&type_system=${type_system}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  getListSubsCompanyCountQuery = (Field, Search, promoId, Period) => {
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        // `/api/companiessubcountquery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}`,
        `/api/companiescountsubsquery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("companiessubcountquery = ",res.data)
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                   LIST Subscriptional Company WHEN SEARCH                                   *
   *                                                                                      */
  //========================================================================================

  // type_system
  getListContractCompanyPageQuery = (
    Field,
    Search,
    promoId,
    Period,
    // type_system,
    Offset,
    Limit
  ) => {
    // console.log("getListSubsCompanyPageQuery type_system=",type_system)
    let fromDate = "1970-01-01";
    // console.log(`field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&type_system=${type_system}&Offset=${Offset}&Limit=${Limit}`)
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        `/api/companiescontractpagequery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  getListContractCompanyCountQuery = (Field, Search, promoId, Period) => {
    // console.log(`field=${Field}&search=${Search}&promoId=${promoId}&Period=${Period}`)
    let fromDate = "1970-01-01";
    if (Period === "all") fromDate = "1970-01-01";
    else if (Period === "today")
      fromDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
    else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
    return axios
      .get(
        `/api/companiescountcontractquery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res.data=",res.data)
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                         CONTRACTS                                        *
   *                                                                                      */
  //========================================================================================

  addContractApi = (setupUser, newContract) => {
    console.log("newContract=", newContract);
    return axios
      .post(
        "/api/newcontract",
        {
          CompanyId: newContract.CompanyId,
          UserId: newContract.UserId,
          CompanyContractId: newContract.CompanyContractId,
          contract_id: newContract.contract_id.trim(),
          contract_description: newContract.contract_description,
          organization: newContract.organization,
          date_of_start_contract: newContract.date_of_start_contract,
          date_of_end_contract: newContract.date_of_end_contract,
          date_of_last_invoice: newContract.date_of_last_invoice,
          amount_of_last_invoice: newContract.amount_of_last_invoice,
          payment_term: newContract.payment_term,
          credits: newContract.credits,
          amount: newContract.amount,
          produuct_line_haf: newContract.produuct_line_haf.status,
          produuct_line_asi: newContract.produuct_line_asi.status,
          status_contract: newContract.status_contract,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  updateContractApi = (setupUser, newContract) => {
    console.log("newContract=", newContract);
    return axios
      .put(
        "/api/newcontract/" + newContract.id,
        {
          CompanyId: newContract.CompanyId,
          UserId: newContract.UserId,
          CompanyContractId: newContract.CompanyContractId,
          contract_id: newContract.contract_id.trim(),
          contract_description: newContract.contract_description,
          organization: newContract.organization,
          date_of_start_contract: newContract.date_of_start_contract,
          date_of_end_contract: newContract.date_of_end_contract,
          date_of_last_invoice: newContract.date_of_last_invoice,
          amount_of_last_invoice: newContract.amount_of_last_invoice,
          payment_term: newContract.payment_term,
          credits: newContract.credits,
          amount: newContract.amount,
          produuct_line_haf: newContract.produuct_line_haf.status,
          produuct_line_asi: newContract.produuct_line_asi.status,
          status_contract: newContract.status_contract,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then(function (res) {
        return res.data;
      });
  };

  postContractTransaction = (newContract, type_system) => {
    console.log("newContract=", newContract);
    return axios
      .post(
        `/api/creditcontract?CompanyContractId=${newContract.CompanyContractId}`,
        {
          UserId: newContract.UserId,
          CompanyId: newContract.CompanyId,
          CompanyContractId: newContract.CompanyContractId,
          // UserId: newContract.UserId,
          incoming: type_system === "ASI" ? 0 : newContract.credits,
          outcoming: 0,
          // credit_date: "?",
          total_sum: newContract.amount_of_last_invoice,
          course: 1,
          subscription_start_date: newContract.date_of_start_contract,
          subscription_end_date: newContract.date_of_end_contract,
          subscription_status: newContract.status_contract === "Active" ? 1 : 0,
          credit_date: newContract.date_of_last_invoice,
          type_system: type_system,
          // product: newContract.product,
          // stripe_price_id: newContract.stripe_price_id,
          // description: newContract.customer,
          status: `C_${newContract.contract_id}`,
          description: `Contract`,
          // payment_json: JSON.stringify(arr.payment_json),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  //========================================================================================
  /*                                                                                      *
   *                                    SEARCH CONTRACT By Period                                    *
   *                                                                                      */
  //========================================================================================

  searchContractByPeriod = (CompanyId, type_system) => {
    // console.log("Stat=",Stat);
    // console.log("type_system=",type_system,"CompanyId=",CompanyId);
    return axios
      .get(
        `/api/searchcontractbyperiod?CompanyId=${CompanyId}&type_system=${type_system}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };

  // updateContrsctApi = (setupUser, newPromo) => {
  //   return axios
  //     .put(
  //       "/api/newpromo/" + newPromo.id,
  //       {
  //         CompanyId: setupUser.CompanyId,
  //         UserId: setupUser.id,
  //         description: newPromo.description,
  //         code: newPromo.code,
  //         bonus: newPromo.bonus,
  //         created: newPromo.created,
  //         expires:
  //           newPromo.expires === "Unlimited"
  //             ? "1970-01-01T00:00:00.000Z"
  //             : newPromo.expires,
  //         status: "",
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
  //         },
  //       }
  //     )
  //     .then(function (res) {
  //       return res.data;
  //     });
  // };
  generateQA = (massive) => {
    // console.log("jsonPattern=",jsonPattern);
    return axios
      .post(
        `/api/generateqa`,
        {
          massive: JSON.stringify(massive),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // let firstname = arr.firstname.trim();
        // let middlename = arr.middlename.trim();
        // let lastname = arr.lastname.trim();
        // let assessment = arr.assessment.trim();
        // firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
        // middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
        // lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

        // const fileName =
        //   scope === "global"
        //     ? `narrative_${assessment}.docx`
        //     : `narrative_${firstname}_${middlename}_${lastname}.docx`;

        const fileName = `qa.docx`;

        var binary = atob(res.data);
        var array = new Uint8Array(binary.length);
        for (var i = 0; i < binary.length; i++) {
          array[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([array]);
        window.saveAs(blob, fileName);
        return res;
      });
  };
  generateQAVer2 = (massive) => {
    // console.log("jsonPattern=",jsonPattern);
    return axios
      .post(
        `/api/generateqa`,
        {
          massive: JSON.stringify(massive),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // let firstname = arr.firstname.trim();
        // let middlename = arr.middlename.trim();
        // let lastname = arr.lastname.trim();
        // let assessment = arr.assessment.trim();
        // firstname = firstname.replace(/[^a-zA-Z0-9]/g, "_");
        // middlename = middlename.replace(/[^a-zA-Z0-9]/g, "_");
        // lastname = lastname.replace(/[^a-zA-Z0-9]/g, "_");

        // const fileName =
        //   scope === "global"
        //     ? `narrative_${assessment}.docx`
        //     : `narrative_${firstname}_${middlename}_${lastname}.docx`;

        const fileName = `qa.docx`;

        var binary = atob(res.data);
        var array = new Uint8Array(binary.length);
        for (var i = 0; i < binary.length; i++) {
          array[i] = binary.charCodeAt(i);
        }
        var blob = new Blob([array]);
        window.saveAs(blob, fileName);
        return res;
      });
  };

testSerpApi = () => {
    // console.log("massive=",massive);
    return axios
      .post(
        `/api/testserpapi`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res=",res.data)
        // return res.data;
        // console.log("res.data[0].message=", res.data[0].message);
        return res.data[0].message;
      });
  };

  connectSerpApi = (id,scope,massive) => {
    // console.log("massive=",massive);
    return axios
      .post(
        `/api/connectserpapi?id=${id}&scope=${scope}`,
        {
          massive: JSON.stringify(massive),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        // console.log("res=",res.data)
        // return res.data;
        // console.log("res.data[0].message=", res.data[0].message);
        return res.data[0].message;
      });
  };

  connectSerpApiVer2 = (id,scope,massive) => {
  // console.log("massive=",massive);
  return axios
    .post(
      `/api/connectserpapi?id=${id}&scope=${scope}`,
      {
        massive: JSON.stringify(massive),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      }
    )
    .then((res) => {
      // console.log("res=",res.data)
      // return res.data;
      // console.log("res.data[0].message=", res.data[0].message);
      return res.data[0].message;
    });
  };
  readFiles = (id) => {
    // console.log("Stat=",Stat);
    // console.log("type_system=",type_system,"CompanyId=",CompanyId);
    return axios
      .get(
        `/api/readdir?id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };
  uploadFiles = (id) => {
    // console.log("massive=",massive);
    return axios
      .post(
        `/upload`,
        {
          id:id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  };



  startCopyDirectories = (defaultCompanyId) => {
    console.log("startCopyDirectories")
    return axios
      .put("/api/startcopydirectories/" + defaultCompanyId,{},{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };


  deleteFile = (id,filename) => {
    // console.log("massive=",massive);
    return axios
      .post(
        `/api/deletefile?id=${id}`,
        {
          id: id,
          filename: filename
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      });
    };

    deleteDirectory = (id) => {
      // console.log("massive=",massive);
      return axios
        .post(
          `/api/deletedirectory?id=${id}`,
          {
            id: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      };

    downloadFile = (id,filename) => {
      // console.log("massive=",massive);
      return axios
        .post(
          `/api/downloadfile?id=${id}`,
          {
            id: id,
            filename: filename
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
            },
          }
        )
        .then((res) => {
          const fileName = filename;
          var binary = atob(res.data);
          var array = new Uint8Array(binary.length);
          for (var i = 0; i < binary.length; i++) {
            array[i] = binary.charCodeAt(i);
          }
          var blob = new Blob([array]);
          window.saveAs(blob, fileName);
          return res;
        });
      };

      testSerpApiFile = (id) => {
        // console.log("massive=",massive);
        return axios
          .post(
            `/api/testserpapi?id=${id}`,
            {
              id: id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
        };

        getProblemList = () => {
          return axios
            .get(`/api/problemlist`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            })
            .then((res) => {
              return res.data;
            });
        };

        addTreatmentPlan = (CompanyId, treeData) => {///-
          // console.log("newImpression=",newImpression);
          // console.log("addTreatmentPlan=");
          const treatment_plan = JSON.stringify(treeData);///-
          return axios
            .post(
              "/api/posttreatmentplan",
              {
                CompanyId: CompanyId,
                treatment_plan: treatment_plan,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };

        addPostPreAndPostSet = (CompanyId, preset, postset) => {
          // console.log("newImpression=",newImpression);
          console.log("addPostPreAndPostSet=");
          return axios
            .post(
              "/api/postpreandpostset",
              {
                CompanyId: CompanyId,
                preset: preset,
                postset: postset,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };

        addOrUpdateRepositories = (item) => {
          // console.log("newImpression=",newImpression);
          // console.log("addOrUpdateRepositories=",item);
          return axios
            .post(
              "/api/addorupdaterepositories",
              {
                CompanyId: item.CompanyId,
                preset: item.preset,
                postset: item.postset,
                // approve_tp: item.approve_tp,
                approve_tp: item.custom===1?0:item.approve_tp,
                treatment_plan: item.treatment_plan,
                propagation_date: item.propagation_date,
                custom: item.custom,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };

        getTreatmentPlan = (CompanyId) => {
          // console.log("getTreatmentPlan CompanyId=",CompanyId)
          return axios
            .get(
              `/api/gettreatmentplan?CompanyId=${CompanyId}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };
        getDefaultUserByEmail = (email) => {
          return axios
            .get(
              `/api/getdefaultuserbyemail?email=${email}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };

//========================================================================================
/*                                                                                      *
 *                                      PROPAGATION                                     *
 *                                                                                      */
//========================================================================================

        getListPropaCompanyPageQuery = (
          Field,
          Search,
          promoId,
          Period,
          Offset,
          Limit,
          mask

        ) => {
          // console.log("mask=",mask);
          let fromDate = "1970-01-01";
          if (Period === "all") fromDate = "1970-01-01";
          else if (Period === "today")
            fromDate = moment()
              .subtract(1, "days")
              .startOf("day")
              .format("YYYY-MM-DD");
          else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
          return axios
            .get(
              `/api/companiespropapagequery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&Offset=${Offset}&Limit=${Limit}&mask=${mask}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            });
        };
        getListPropaCompanyCountQuery = (Field, Search, promoId, Period,mask) => {
          let fromDate = "1970-01-01";
          if (Period === "all") fromDate = "1970-01-01";
          else if (Period === "today")
            fromDate = moment()
              .subtract(1, "days")
              .startOf("day")
              .format("YYYY-MM-DD");
          else fromDate = moment().startOf(Period).format("YYYY-MM-DD");
          return axios
            .get(
              `/api/companiescountpropaquery?field=${Field}&search=${Search}&promoId=${promoId}&Period=${fromDate}&mask=${mask}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              // console.log("res.data=",res.data)
              return res.data;
            });
        };

        deletePropagation = (CompanyId) => {
          return axios
            .delete("/api/propagation/" + CompanyId, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
        };

        startPropagation = (defaultCompanyId) => {
          // console.log("defaultCompanyId=",defaultCompanyId);
          return axios
            .put("/api/startpropagation/" + defaultCompanyId,{},{
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
        };

        startPropagationImpressions = (defaultCompanyId) => {
          // console.log("defaultCompanyId=",defaultCompanyId);
          return axios
            .put("/api/startpropagationimpressions/" + defaultCompanyId,{},{
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
        };

        startPropagationImpressionsForOneCompany = (CompanyId,defaultCompanyId) => {
          // console.log("defaultCompanyId=",defaultCompanyId);
          return axios
            .put("/api/startpropagationimpressionsforonecompany/" + defaultCompanyId,{
              CompanyId:CompanyId
            },{
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
        };

        generatePreViewTreat = (id, setupUser, setupOrg, scope, migrationMassive2, pgoTree) => {
          console.log("pgoTree=",pgoTree);
          return axios
            .post(
              `/api/previewtreat?id=${id}&firstname=${setupUser.firstname}&lastname=${setupUser.lastname}&logo=${setupOrg.foto_trg}&scope=${scope}`,
              {
                problem_list: JSON.stringify(migrationMassive2),
                pgo_tree: JSON.stringify(pgoTree)
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
                },
              }
            )
            .then((res) => {
              // console.log("res=",res);
              return res;
            });
        };


}





export default HafServices;
