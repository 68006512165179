import initialState from "./state";
import {
  Constants,
  jsonShortElem,
  patternClientInfoContent,
  compareJsonSection,
  sectionsDeskToDisplay,
  putTitleInTree
} from "../api";
import ASIPattern from "./ASIPattern";
import adultPattern from "./adultPattern";
import adolescentPattern from "./adolescentPattern";
import blockAlcohol from "./jsonsAdolescent/blockAlcohol"
import blockCannabis from "./jsonsAdolescent/blockCannabis"
import blockCocaine from "./jsonsAdolescent/blockCocaine"
import blockCrack from "./jsonsAdolescent/blockCrack"
import blockCrank from "./jsonsAdolescent/blockCrank"
import blockEcstasy from "./jsonsAdolescent/blockEcstasy"
import blockHallucinogens from "./jsonsAdolescent/blockHallucinogens"
import blockHeroin from "./jsonsAdolescent/blockHeroin"
import blockInhalants from "./jsonsAdolescent/blockInhalants"
import blockMeth from "./jsonsAdolescent/blockMeth"
import blockMethadone from "./jsonsAdolescent/blockMethadone"
import blockOpiates from "./jsonsAdolescent/blockOpiates"
import blockSedative from "./jsonsAdolescent/blockSedative"
import blockTobacco from "./jsonsAdolescent/blockTobacco"
import blockOther from "./jsonsAdolescent/blockOther"
import moment from "moment";

const evtreducer = (state = initialState, action) => {
  let objectTmp = {};
  let valueTmp = [];

  switch (action.type) {
    case "ERROR_MSG":
      return Object.assign({}, state, { errorMsg: action.payload });
    case "ITEMS_IS_LOADING":
      return Object.assign({}, state, { itemsIsLoading: action.payload });
    case "BLOCK_TRANSACTION":
      return Object.assign({}, state, { blockTransaction: action.payload });
    case "STATUS_DIALOG_OPEN":
      return Object.assign({}, state, { loginDialogOpen: action.payload });
    case "LOGIN_MESSAGE":
      return Object.assign({}, state, { loginMessage: action.payload });
    case "ITEMS_IS_FAILED":
      return Object.assign({}, state, { itemsIsFailed: action.payload });
    case "LOGIN_INPUT_CHANGE":
      return Object.assign({}, state, {
        authParam: {
          login: action.payload,
          password: state.authParam.password,
        },
      });
    case "PASSWORD_INPUT_CHANGE":
      return Object.assign({}, state, {
        authParam: { login: state.authParam.login, password: action.payload },
      });
    case "CLEAR_AUTH_PARAM":
      return Object.assign({}, state, {
        authParam: { login: "", password: "" },
      });
    case "LOGIN_IN_HAF":
      return Object.assign({}, state, { isLoggedIn: action.payload });
    case "REMOTESESSION_IN_HAF":
      return Object.assign({}, state, { isRemoteSession: action.payload });
    case "FETCH_USER_DATA":
      return Object.assign({}, state, { setupUser: action.payload, showButtonChange: false });
    case "FETCH_NEWUSER_COMPANYID_DATA":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, CompanyId: action.payload },
      });
    case "TAKE_ITEM_USER":
      let new_str = "";
      let num =
        typeof action.payload.email === "undefined"
          ? 0
          : action.payload.email.length;
      while (num-- > 0) new_str += "•";

      tmp = Object.assign({}, state, { newUser: action.payload });

      return Object.assign({}, tmp, {
        newUser: {
          ...tmp.newUser,
          email: new_str,
          email_repeat: new_str,
          email_onblur: action.payload.email,
          email_repeat_onblur: action.payload.email,
        },
      });
    case "FETCH_COMPANY_DATA":
      // console.log("setupOrg", state.setupOrg);
      return Object.assign({}, state, {
        ...state.setupOrg,
        setupOrg: action.payload,
        changed: false,
      });
    case "OPEN_NEW_USER_DIALOG":
      return Object.assign({}, state, { dialogOpen: action.payload });
    case "CLOSE_NEW_USER_DIALOG":
      return Object.assign({}, state, { dialogOpen: action.payload });

    case "OPEN_UPDATE_USER_DIALOG":
      return Object.assign({}, state, { dialogUpdateOpen: action.payload });
    case "CLOSE_UPDATE_USER_DIALOG":
      return Object.assign({}, state, { dialogUpdateOpen: action.payload });
    case "OPEN_RESET_USER_DIALOG":
      return Object.assign({}, state, { dialogResetOpen: action.payload });
    case "CLOSE_RESET_USER_DIALOG":
      return Object.assign({}, state, { dialogResetOpen: action.payload });
    case "OPEN_NEW_MESSAGE_DIALOG":
      return Object.assign({}, state, { dialogNewMessageOpen: action.payload });
    case "CLOSE_NEW_MESSAGE_DIALOG":
      return Object.assign({}, state, { dialogNewMessageOpen: action.payload });
    case "CLEAR_NEW_MESSAGE_DIALOG":
      return Object.assign({}, state, {
        newMessage: { ...state.newMessage, msg: "", status: "", msg_date: "" },
      });
    case "CHANGE_STATUS_MESSAGE":
      return Object.assign({}, state, {
        newMessage: { ...state.newMessage, status: "old" },
      });
    case "TAKE_ITEM_MSG":
      return Object.assign({}, state, {
        newMessage: {
          ...state.newMessage,
          CompanyId: action.payload.CompanyId,
          ToUserId: action.payload.id,
        },
      });
    case "TAKE_ITEM_MSG_FROM":
      return Object.assign({}, state, {
        newMessage: { ...state.newMessage, FromUserId: action.payload },
      });
    case "ORGANIZATIONAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          organization: action.payload,
        },
      });
    case "CONTACTPERSON_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          contact_name: action.payload,
        },
      });
    case "ADDRESSLINE1_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          address_line_1: action.payload,
        },
      });
    case "ADDRESSLINE2_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          address_line_2: action.payload,
        },
      });
    case "CITY_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, city: action.payload },
      });
    case "STATEPROVINCE_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, state: action.payload },
      });
    case "ZIPCODE_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          zip_code: action.payload,
        },
      });
    case "EMAIL_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, email: action.payload },
      });
    case "YOURCOUNTRY_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, country: action.payload },
      });
    case "PHONE_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, phone: action.payload },
      });
    case "UPLOADLOGO_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: true, foto: action.payload },
      });
    case "UPLOAD_DOCUMENT_SUCCESS":
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
          foto_trg: action.payload,
        },
      });
    case "REFERRAL_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, referral: action.payload },
      });
    case "REFERRAL_PARENT_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, referral_parent: action.payload },
      });
    case "UPLOAD_DOCUMENT_FAIL":
      return state;
    case "SAVE_NEW_COMPANY":
      return Object.assign({}, state, { setupOrg: action.payload });

    case "SAVE_BUTTON_DISABLE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, changed: action.payload },
      });
    case "SAVE_TREATMENT_PLAN_BUTTON_DISABLE":
      // return Object.assign({}, state, { presetORpostsetChanged: action.payload }); ///-
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository, presetORpostsetChanged: action.payload }, ///+
      });
    case "LOAD_USERS":
      return Object.assign({}, state, { setupUserArr: action.payload });
    case "LOAD_USERS1":
      return Object.assign({}, state, { setupUserArr: action.payload });
    case "LOAD_USERS_ALL_COUNT": {
      return Object.assign({}, state, { totalUsers: action.payload });
    }
    case "LOAD_PAGE_USER_COUNT": {
      return Object.assign({}, state, { pagesCountUser: action.payload });
    }
    case "SET_CURRENT_USER_PAGE": {
      return Object.assign({}, state, { currentPageUser: action.payload });
    }
    case "INIT_USER_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesUser,
        state.pagesCountUser
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesUser: arr });
    }
    case "SET_VIS_USER_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesUser,
        state.pagesCountUser
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountUser - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesUser: arr });
    }
    case "NEED_REFRESH_USER_LIST": {
      return Object.assign({}, state, {
        needRefresUserList: !state.needRefresUserList,
      });
    }

    case "LOAD_HISTORIES1": {
      return Object.assign({}, state, { setupHistoryArr: action.payload });
    }
    case "LOAD_HISTORIES_ALL_COUNT": {
      return Object.assign({}, state, { totalHistories: action.payload });
    }
    case "LOAD_PAGE_COUNT": {
      return Object.assign({}, state, { pagesCount: action.payload });
    }
    case "SET_CURRENT_PAGE": {
      return Object.assign({}, state, { currentPage: action.payload });
    }
    case "SET_VIS_PAGE": {
      let arr = [];
      const diapazonPagesLocal = Math.min(
        state.diapazonPages,
        state.pagesCount
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPagesLocal + 2,
          state.pagesCount - diapazonPagesLocal
        )
      );
      arr = [...Array(diapazonPagesLocal)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPages: arr });
    }
    case "INIT_PAGINATION": {
      let arr = [];
      const diapazonPagesLocal = Math.min(
        state.diapazonPages,
        state.pagesCount
      );
      arr = [...Array(diapazonPagesLocal)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPages: arr });
    }
    case "NEED_REFRESH_HISTORY_LIST": {
      return Object.assign({}, state, {
        needRefresHistoryList: !state.needRefresHistoryList,
      });
    }
    //----------------------------------------------------------------------
    case "LOAD_CREDITS1": {
      return Object.assign({}, state, { setupCreditArr: action.payload });
    }
    case "LOAD_CREDITS_ALL_COUNT": {
      return Object.assign({}, state, { totalCredits: action.payload });
    }
    case "LOAD_PAGE_CREDIT_COUNT": {
      return Object.assign({}, state, { pagesCountCredit: action.payload });
    }
    case "SET_CURRENT_CREDIT_PAGE": {
      return Object.assign({}, state, { currentPageCredit: action.payload });
    }
    case "SET_VIS_CREDIT_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCredit,
        state.pagesCountCredit
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountCredit - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesCredit: arr });
    }
    case "INIT_CREDIT_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCredit,
        state.pagesCountCredit
      );
      // console.log("diapazonPages=",diapazonPages)
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      // console.log("arr=",arr)
      return Object.assign({}, state, { visPagesCredit: arr });
    }
    case "NEED_REFRESH_CREDIT_LIST": {
      return Object.assign({}, state, {
        needRefresCreditList: !state.needRefresCreditList,
      });
    }
    //--------------------------------------------------------------------------------------
    case "LOAD_COMPANIES1": {
      return Object.assign({}, state, { setupCompanyArr: action.payload });
    }
    case "LOAD_COMPANIES_ALL_COUNT": {
      return Object.assign({}, state, { totalCompanies: action.payload });
    }
    case "LOAD_PAGE_COMPANY_COUNT": {
      return Object.assign({}, state, { pagesCountCompany: action.payload });
    }
    case "SET_CURRENT_COMPANY_PAGE": {
      return Object.assign({}, state, { currentPageCompany: action.payload });
    }
    case "SET_VIS_COMPANY_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompany,
        state.pagesCountCompany
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountCompany - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesCompany: arr });
    }
    case "INIT_COMPANY_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompany,
        state.pagesCountCompany
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesCompany: arr });
    }
      //--------------------------------------------------------------------------------------
      case "LOAD_COMPANIES1_SUBS": {
        return Object.assign({}, state, { setupCompanySubsArr: action.payload });
      }
      case "LOAD_COMPANIES_SUBS_ALL_COUNT": {
        return Object.assign({}, state, { totalCompaniesSubs: action.payload });
      }
      case "LOAD_PAGE_COMPANY_SUBS_COUNT": {
        return Object.assign({}, state, { pagesCountCompanySubs: action.payload });
      }
      case "SET_CURRENT_COMPANY_SUBS_PAGE": {
        return Object.assign({}, state, { currentPageCompanySubs: action.payload });
      }
      case "SET_VIS_COMPANY_SUBS_PAGE": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanySubs,
          state.pagesCountCompanySubs
        );
        const minVis = Math.max(
          0,
          Math.min(
            action.payload - diapazonPages + 2,
            state.pagesCountCompanySubs - diapazonPages
          )
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i + minVis;
        });
        return Object.assign({}, state, { visPagesCompanySubs: arr });
      }
      case "INIT_COMPANY_SUBS_PAGINATION": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanySubs,
          state.pagesCountCompanySubs
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i;
        });
        return Object.assign({}, state, { visPagesCompanySubs: arr });
      }
      //--------------------------------------------------------------------------------------
      case "LOAD_COMPANIES1_ASI_SUBS": {
        return Object.assign({}, state, { setupCompanyAsiSubsArr: action.payload });
      }
      case "LOAD_COMPANIES_ASI_SUBS_ALL_COUNT": {
        return Object.assign({}, state, { totalCompaniesAsiSubs: action.payload });
      }
      case "LOAD_PAGE_COMPANY_ASI_SUBS_COUNT": {
        return Object.assign({}, state, { pagesCountCompanyAsiSubs: action.payload });
      }
      case "SET_CURRENT_COMPANY_ASI_SUBS_PAGE": {
        return Object.assign({}, state, { currentPageCompanyAsiSubs: action.payload });
      }
      case "SET_VIS_COMPANY_ASI_SUBS_PAGE": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanyAsiSubs,
          state.pagesCountCompanyAsiSubs
        );
        const minVis = Math.max(
          0,
          Math.min(
            action.payload - diapazonPages + 2,
            state.pagesCountCompanyAsiSubs - diapazonPages
          )
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i + minVis;
        });
        return Object.assign({}, state, { visPagesCompanyAsiSubs: arr });
      }
      case "INIT_COMPANY_ASI_SUBS_PAGINATION": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanyAsiSubs,
          state.pagesCountCompanyAsiSubs
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i;
        });
        return Object.assign({}, state, { visPagesCompanyAsiSubs: arr });
      }
      //--------------------------------------------------------------------------------------
      case "LOAD_COMPANIES1_HAF_SUBS": {
        return Object.assign({}, state, { setupCompanyHafSubsArr: action.payload });
      }
      case "LOAD_COMPANIES_HAF_SUBS_ALL_COUNT": {
        return Object.assign({}, state, { totalCompaniesHafSubs: action.payload });
      }
      case "LOAD_PAGE_COMPANY_HAF_SUBS_COUNT": {
        return Object.assign({}, state, { pagesCountCompanyHafSubs: action.payload });
      }
      case "SET_CURRENT_COMPANY_HAF_SUBS_PAGE": {
        return Object.assign({}, state, { currentPageCompanyHafSubs: action.payload });
      }
      case "SET_VIS_COMPANY_HAF_SUBS_PAGE": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanyHafSubs,
          state.pagesCountCompanyHafSubs
        );
        const minVis = Math.max(
          0,
          Math.min(
            action.payload - diapazonPages + 2,
            state.pagesCountCompanyHafSubs - diapazonPages
          )
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i + minVis;
        });
        return Object.assign({}, state, { visPagesCompanyHafSubs: arr });
      }
      case "INIT_COMPANY_HAF_SUBS_PAGINATION": {
        let arr = [];
        const diapazonPages = Math.min(
          state.diapazonPagesCompanyHafSubs,
          state.pagesCountCompanyHafSubs
        );
        arr = [...Array(diapazonPages)].map((page, i) => {
          return i;
        });
        return Object.assign({}, state, { visPagesCompanyHafSubs: arr });
      }
    //--------------------------------------------------------------------------------------
    case "LOAD_COMPANIESCASHFLOW1": {
      return Object.assign({}, state, {
        setupCompanyCashflowArr: action.payload,
      });
    }
    case "LOAD_COMPANIESCASHFLOW_ALL_COUNT": {
      return Object.assign({}, state, {
        totalCompaniesCashflow: action.payload,
      });
    }
    case "LOAD_PAGE_COMPANYCASHFLOW_COUNT": {
      return Object.assign({}, state, {
        pagesCountCompanyCashflow: action.payload,
      });
    }
    case "SET_CURRENT_COMPANYCASHFLOW_PAGE": {
      return Object.assign({}, state, {
        currentPageCompanyCashflow: action.payload,
      });
    }
    case "SET_VIS_COMPANYCASHFLOW_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyCashflow,
        state.pagesCountCompanyCashflow
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountCompanyCashflow - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesCompanyCashflow: arr });
    }
    case "INIT_COMPANYCASHFLOW_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyCashflow,
        state.pagesCountCompanyCashflow
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesCompanyCashflow: arr });
    }
    //---------------------------------------------------------------------------------------
    case "LOAD_IMPRESSION1":
      return Object.assign({}, state, { setupImpressionArr: action.payload });
    case "LOAD_IMPRESSIONS_ALL_COUNT": {
      return Object.assign({}, state, { totalImpressions: action.payload });
    }
    case "LOAD_PAGE_IMPRESSION_COUNT": {
      return Object.assign({}, state, { pagesCountImpression: action.payload });
    }
    case "SET_CURRENT_IMPRESSION_PAGE": {
      return Object.assign({}, state, {
        currentPageImpression: action.payload,
      });
    }
    case "INIT_IMPRESSION_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesImpression,
        state.pagesCountImpression
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesImpression: arr });
    }
    case "SET_VIS_IMPRESSION_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesImpression,
        state.pagesCountImpression
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountImpression - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesImpression: arr });
    }
    //--------------------------------------------------------------------------------------
    case "LOAD_COMPANIES1_CONTRACT": {
      return Object.assign({}, state, { setupCompanyContractArr: action.payload });
    }
    case "LOAD_COMPANIES_CONTRACT_ALL_COUNT": {
      // console.log(" action.payload=", action.payload)
      return Object.assign({}, state, { totalCompaniesContract: action.payload });
    }
    case "LOAD_PAGE_COMPANY_CONTRACT_COUNT": {
      return Object.assign({}, state, { pagesCountCompanyContract: action.payload });
    }
    case "SET_CURRENT_COMPANY_CONTRACT_PAGE": {
      return Object.assign({}, state, { currentPageCompanyContract: action.payload });
    }
    case "SET_VIS_COMPANY_CONTRACT_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyContract,
        state.pagesCountCompanyContract
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountCompanyContract - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesCompanyContract: arr });
    }
    case "INIT_COMPANY_CONTRACT_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyContract,
        state.pagesCountCompanyContract
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesCompanyContract: arr });
    }
    //--------------------------------------------------------------------------------------

    case "LOAD_COMPANIES1_PROPA": {
      return Object.assign({}, state, { setupCompanyPropaArr: action.payload });
    }
    case "LOAD_COMPANIES1_PROPA_FOR_MODAL": {
      return Object.assign({}, state, { setupCompanyPropaArrForModal: action.payload });
    }
    case "LOAD_COMPANIES_PROPA_ALL_COUNT": {
      return Object.assign({}, state, { totalCompaniesPropa: action.payload });
    }
    case "LOAD_PAGE_COMPANY_PROPA_COUNT": {
      return Object.assign({}, state, { pagesCountCompanyPropa: action.payload });
    }
    case "SET_CURRENT_COMPANY_PROPA_PAGE": {
      return Object.assign({}, state, { currentPageCompanyPropa: action.payload });
    }
    case "SET_VIS_COMPANY_PROPA_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyPropa,
        state.pagesCountCompanyPropa
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountCompanyPropa - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesCompanyPropa: arr });
    }
    case "INIT_COMPANY_PROPA_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesCompanyPropa,
        state.pagesCountCompanyPropa
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesCompanyPropa: arr });
    }
//--------------------------------------------------------------------------------------
    case "CREDIT_MANAGEMENT_INPUT_CHANGE": {
      let foo = {};
      let tmp = action.payload.value;
      let rep = /^0/;
      if (rep.test(tmp)) {
        tmp = Number(tmp);
      }
      foo = Object.assign({}, state, {
        setupCompanyArr: state.setupCompanyArr.map((el) =>
          el.CompanyId === action.payload.item.CompanyId
            ? { ...el, addField: tmp }
            : el
        ),
      });
      return foo;
    }
    case "CREDIT_MANAGEMENT_INPUT_INC": {
      let foo = {};
      foo = Object.assign({}, state, {
        setupCompanyArr: state.setupCompanyArr.map((el) =>
          el.CompanyId === action.payload.item.CompanyId
            ? { ...el, addField: Number(el.addField) + action.payload.inc }
            : el
        ),
      });
      return foo;
    }
    // case "OPEN_NEW_IMPRESSION_DIALOG":
    //   return Object.assign({}, state, { dialogOpenImpression: action.payload });
    case "CLOSE_NEW_IMPRESSION_DIALOG":
      return Object.assign({}, state, { dialogOpenImpression: action.payload });
    case "NEED_REFRESH_IMPRESSION_LIST": {
      return Object.assign({}, state, {
        needRefresImpressionList: !state.needRefresImpressionList,
      });
    }
    // case "CLEAR_NEW_IMPRESSION_DIALOG":
    //   return Object.assign({}, state, {
    //     newImpression: {
    //       ...state.newImpression,
    //       id: 0,
    //       CompanyId: 0,
    //       shortname: "",
    //       checkbox_options: "",
    //     },
    //   });
    // case "OPEN_UPDATE_IMPRESSION_DIALOG":
    //   return Object.assign({}, state, {
    //     dialogUpdateOpenImpression: action.payload,
    //   });
    case "CLOSE_UPDATE_IMPRESSION_DIALOG":
      return Object.assign({}, state, {
        dialogUpdateOpenImpression: action.payload,
      });

    //--------------------------------------------------------------------------------------------
    case "REGISTR_FIRSTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, firstname: action.payload },
      });
    case "REGISTR_LASTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, lastname: action.payload },
      });
    case "REGISTR_PROF_CREDENTIAL_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, prof_credential: action.payload },
      });
    case "REGISTR_ORGANIZATION_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, organization: action.payload },
      });
    case "REGISTR_PROMO_INPUT_CHANGE":
      return Object.assign({}, state, { promoRegistr: action.payload });

    case "REGISTR_COUNTRY_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, country: action.payload },
      });
    case "REGISTR_PHONE_CHANGE":
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, phone: action.payload },
      });

    case "REGISTR_EMAIL_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, email: action.payload },
      });
    case "BLUR_EMAIL_INPUT_CHANGE": {
      state.setupUser.email = state.setupUser.email.replace(/^\s+|\s+$/g, "");
      console.log("state.setupUser.email=",state.setupUser.email);
      let new_str = "";
      // console.log("state.setupUser.email=",state.setupUser.email);
      let num =
        typeof state.setupUser.email === "undefined"
          ? 0
          : state.setupUser.email.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          email_onblur: state.setupUser.email,
          // email: new_str,
          email: state.setupUser.email,
        },
      });
    }
    case "FOCUS_EMAIL_INPUT_CHANGE": {
      let email =
        typeof state.setupUser.email_onblur === "undefined"
          ? ""
          : state.setupUser.email_onblur;
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, email_onblur: "", email: email },
      });
    }
    case "BLUR_EMAILREPEAT_INPUT_CHANGE": {
      state.setupUser.email_repeat = state.setupUser.email_repeat.replace(
        /^\s+|\s+$/g,
        ""
      );
      let new_str = "";
      // console.log("state.setupUser.email_repeat=",state.setupUser.email_repeat);
      let num =
        typeof state.setupUser.email_repeat === "undefined"
          ? 0
          : state.setupUser.email_repeat.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          email_repeat_onblur: state.setupUser.email_repeat,
          // email_repeat: new_str,
          email_repeat: state.setupUser.email_repeat,
        },
      });
    }
    case "FOCUS_EMAILREPEAT_INPUT_CHANGE": {
      let email_repeat =
        typeof state.setupUser.email_repeat_onblur === "undefined"
          ? ""
          : state.setupUser.email_repeat_onblur;
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          email_repeat_onblur: "",
          email_repeat: email_repeat,
        },
      });
    }

    case "REGISTR_EMAILREPEAT_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, email_repeat: action.payload },
      });

    case "REGISTR_PASSWORD_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, password: action.payload },
      });
    case "REGISTR_CONFIRM_INPUT_CHANGE":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, confirm: action.payload },
      });

    case "REGISTR_NEW_COMPANY":
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          firstname: "",
          lastname: "",
          organization: "",
          email: "",
          email_onblur: "",
          email_repeat: "",
          email_repeat_onblur: "",
          password: "",
          confirm: "",
          degree: "",
          prof_credential : "",
        },
        errorMsg: action.payload,
        setupOrg: { ...state.setupOrg, phone: "" },
        // promoRegistr: "",
        promoSetup: { id: "0", code: "" },
      });
    case "CLEAR_SETUPUSER":
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          id: 0,
          CompanyId: 0,
          firstname: "",
          lastname: "",
          organization: "",
          // country:'United States',
          email: "",
          email_onblur: "",
          email_repeat: "",
          email_repeat_onblur: "",
          password: "",
          confirm: "",
          created: "",
          role: "",
          status: "",
          attempts: 0,
          showgettingstarted: 0,
          showtour : 0,
          verifycode: "",
          version_word: "",
          degree: "",
          prof_credential: ""
        },
      });
    case "REFRESH_USER_DATA":
      return Object.assign({}, state, {
        setupUser: {
          ...state.setupUser,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          organization: action.payload.organization,
          // country:'United States',
          email: action.payload.email,
          role: action.payload.role,
          status: action.payload.status,
          version_word: action.payload.version_word,
          degree: action.payload.degree,
          prof_credential: action.payload.prof_credential,
        },
      });
    case "NEW_FIRSTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, firstname: action.payload },
      });
    case "NEW_LASTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, lastname: action.payload },
      });
    case "NEW_ROLE_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, role: action.payload },
      });

    case "NEW_EMAIL_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, email: action.payload },
      });
    case "NEW_EMAIL_INPUT_BLUR": {
      state.newUser.email = state.newUser.email.replace(/^\s+|\s+$/g, "");
      let new_str = "";
      let num =
        typeof state.newUser.email === "undefined"
          ? 0
          : state.newUser.email.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        newUser: {
          ...state.newUser,
          email_onblur: state.newUser.email,
          email: new_str,
        },
      });
    }

    case "NEW_EMAIL_INPUT_FOCUS": {
      let email =
        typeof state.newUser.email_onblur === "undefined"
          ? ""
          : state.newUser.email_onblur;
      return Object.assign({}, state, {
        newUser: { ...state.newUser, email_onblur: "", email: email },
      });
    }
    case "NEW_EMAILREPEAT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        newUser: { ...state.newUser, email_repeat: action.payload },
      });
    }
    case "NEW_EMAILREPEAT_INPUT_BLUR": {
      state.newUser.email_repeat = state.newUser.email_repeat.replace(
        /^\s+|\s+$/g,
        ""
      );
      let new_str = "";
      let num =
        typeof state.newUser.email_repeat === "undefined"
          ? 0
          : state.newUser.email_repeat.length;
      while (num-- > 0) new_str += "•";
      return Object.assign({}, state, {
        newUser: {
          ...state.newUser,
          email_repeat_onblur: state.newUser.email_repeat,
          email_repeat: new_str,
        },
      });
    }
    case "NEW_EMAILREPEAT_INPUT_FOCUS": {
      let email_repeat =
        typeof state.newUser.email_repeat_onblur === "undefined"
          ? ""
          : state.newUser.email_repeat_onblur;
      return Object.assign({}, state, {
        newUser: {
          ...state.newUser,
          email_repeat_onblur: "",
          email_repeat: email_repeat,
        },
      });
    }

    case "NEW_PASSWORD_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, password: action.payload },
      });
    case "NEW_CONFIRM_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, confirm: action.payload },
      });
    case "NEW_VERSIONWORD_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, version_word: action.payload },
      });
    case "NEW_DEGREE_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, degree: action.payload },
      });
    case "NEW_PROF_CREDENTIAL_INPUT_CHANGE":
      return Object.assign({}, state, {
        newUser: { ...state.newUser, prof_credential: action.payload },
      });
    case "CLEAR_NEW_USER_DIALOG":
      return Object.assign({}, state, {
        newUser: {
          ...state.newUser,
          firstname: "",
          lastname: "",
          role: "Counselor",
          version_word: "Desktop version",
          degree: "",
          prof_credential:"",
          email: "",
          email_onblur: "",
          email_repeat: "",
          email_repeat_onblur: "",
          password: "",
          confirm: "",
        },
      });
    case "OPEN_NEW_USER_LIST":
      return Object.assign({}, state, {
        settingsMenuOpen: !state.settingsMenuOpen,
      });
    case "CLOSE_NEW_USER_LIST":
      return Object.assign({}, state, { settingsMenuOpen: action.payload });
    case "OPEN_BUY_CREDIT_DIALOG":
      return Object.assign({}, state, { dialogOpenBuyCredit: action.payload });
    case "CLEAR_BUY_CREDIT_DIALOG":
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          cardholder: "",
          card_emai: "",
          incoming: 0,
          total_sum: 0,
          // allow_input_referral: false,
          referral_parent:"",
        },
      });
    case "CLEAR_REFERRAL_IN_DIALOG":
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          referral_parent:"",
        },
      });
    // case "ALLOW_INPUT_REFERRAL_CREDIT_DIALOG" :
    // return Object.assign({}, state, {
    //   newCredit: {
    //     ...state.newCredit,
    //     allow_input_referral: action.payload,
    //   },
    // });
    case "REFERRALPARENT_DIALOG_INPUT_CHANGE": {
      return Object.assign({}, state, {
        newCredit: { ...state.newCredit, referral_parent: action.payload },
      });
    }
    // case "USE_MODAL_OR_STRIPE": {
    //   return Object.assign({}, state, { useModalOrStripe: action.payload });
    // }
    case "CLOSE_BUY_CREDIT_DIALOG":
      return Object.assign({}, state, { dialogOpenBuyCredit: action.payload });
    case "CREDIT_INCOMING_INPUT_CHANGE":
      let tmp = action.payload;
      let rep = /^0/;
      if (rep.test(tmp)) {
        // tmp = tmp.replace(rep, "");
        tmp = Number(tmp);
      }
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          incoming: tmp,
          total_sum: Number(tmp) * state.newCredit.course,
          products: state.newCredit.products.map((el) =>
            el.name === "Credit"
              ? {
                  ...el,
                  quantity: tmp,
                  total_sum: Number(tmp) * state.newCredit.course,
                }
              : { ...el, quantity: 0 }
          ),
        },
      });
    case "CREDIT_INCOMING_INPUT_INC":
      // return Object.assign({}, state, {
      //   newCredit: { ...state.newCredit, incoming: Number(state.newCredit.incoming)+action.payload, total_sum: (Number(state.newCredit.incoming)+action.payload)*state.newCredit.course }
      // });
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          incoming:
            Number(
              state.newCredit.products.find((e) => e.name === "Credit").quantity
            ) + action.payload,
          total_sum:
            (Number(state.newCredit.incoming) + action.payload) *
            state.newCredit.course,
          products: state.newCredit.products.map((el) =>
            el.name === "Credit"
              ? {
                  ...el,
                  quantity:
                    Number(
                      state.newCredit.products.find((e) => e.name === "Credit")
                        .quantity
                    ) + action.payload,
                  total_sum:
                    (Number(
                      state.newCredit.products.find((e) => e.name === "Credit")
                        .quantity
                    ) +
                      action.payload) *
                    state.newCredit.course,
                }
              : { ...el, quantity: 0 }
          ),
        },
      });
    case "CREDIT_PRODUCT_INPUT_CHANGE": {
      // return Object.assign({}, state, {
      //   newCredit: { ...state.newCredit, product_name: action.payload  }
      // });
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          product_name: action.payload,
          incoming: state.newCredit.products.find(
            (e) => e.name === action.payload
          ).incoming,
          total_sum:
            action.payload === "Credit"
              ? state.newCredit.course
              : state.newCredit.products.find((e) => e.name === action.payload)
                  .course,
          products: state.newCredit.products.map((el) =>
            el.name === action.payload && el.name === "Credit"
              ? { ...el, quantity: 1, total_sum: el.course }
              : el.name === action.payload
              ? { ...el, quantity: 1, total_sum: el.course }
              : { ...el, quantity: 0, total_sum: 0 }
          ),
        },
      });
    }
    case "CREDIT_INCOMING_RESET":
      // return Object.assign({}, state, {
      //   newCredit: { ...state.newCredit, incoming: Number(state.newCredit.incoming)+action.payload, total_sum: (Number(state.newCredit.incoming)+action.payload)*state.newCredit.course }
      // });
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          incoming: 0,
          total_sum: 0,
          product_name: "Credit",
          products: state.newCredit.products.map((el) =>
            el.name === "Credit"
              ? { ...el, quantity: 0, total_sum: 0 }
              : { ...el, quantity: 0, total_sum: 0 }
          ),
        },
      });
    case "CREDIT_EMAIL_RECEPIENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        newCredit: { ...state.newCredit, card_email: action.payload },
      });
    }
    case "CALCULATE_TOTAL_SUM":
      return Object.assign({}, state, {
        newCredit: { ...state.newCredit, total_sum: action.payload },
      });
    case "CREDIT_CARD_HOLDER_INPUT_CHANGE": {
      return Object.assign({}, state, {
        newCredit: { ...state.newCredit, cardholder: action.payload },
      });
    }
    case "SET_FIELD_CARD_EMAIL": {
      return Object.assign({}, state, {
        newCredit: { ...state.newCredit, card_email: action.payload },
      });
    }
    // case "CREDIT_SAVE_CARD_DETAILS_INPUT_CHANGE": {
    //   return Object.assign({}, state, {
    //     newCredit: { ...state.newCredit, save_card_details_changed: true, save_card_details: action.payload },
    //   });
    // }
    // case "CREDIT_RECURRING_PAYMENT_INPUT_CHANGE": {
    //   return Object.assign({}, state, {
    //     newCredit: { ...state.newCredit, recurring_payment_changed: true, recurring_payment: action.payload },
    //   });
    // }
    // case "CREDIT_SAVE_CARD_DETAILS_CHANGED": {
    //   return Object.assign({}, state, {
    //     newCredit: { ...state.newCredit, save_card_details_changed: false },
    //   });
    // }
    // case "CREDIT_RECURRING_PAYMENT_CHANGED": {
    //   return Object.assign({}, state, {
    //     newCredit: { ...state.newCredit, recurring_payment_changed: false },
    //   });
    // }
    case "CALC_SUM_CREDITS":
      return Object.assign({}, state, { balanceCredits: action.payload });
    case "FILL_NEW_CREDIT":
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          description: action.payload.description,
          status: action.payload.payment.status,
          payment_json: action.payload.payment,
        },
      });
    case "SET_NAVIGATION_COUNSELOR":
      return Object.assign({}, state, {
        navigation: {
          items: [
            {
              _tag: "CSidebarNavItem",
              name: "New Assessment",
              url: "/selectnewassessment",
              to: "/selectnewassessment",
              tobackup: "/selectnewassessment",
              addLinkClass: "haf-navbtn-new new-assessment",
              fontIcon: "fas fa-plus",
              // attributes: { disabled: false },
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "My Assessments",
              url: "/myassessments",
              to: "/myassessments",
              tobackup: "/myassessments",
              fontIcon: "fas fa-user",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "All Assessments",
              url: "/allassessments",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-users",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "/setup",
              tobackup: "/setup",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
/*            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: false,
            },
*/
            {
              _tag: "CSidebarNavItem",
              name: "Referral",
              url: "/referral",
              to: "/referral",
              tobackup: "/referral",
              fontIcon: "fas fa-share",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Getting Started",
              url: "/gettingstarted",
              to: "/gettingstarted",
              tobackup: "/gettingstarted",
              fontIcon: "icon-question",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
          ],
        },
      });
    case "SET_NAVIGATION_ADMINISTRATOR":
      return Object.assign({}, state, {
        navigation: {
          items: [
            {
              _tag: "CSidebarNavItem",
              name: "New Assessment",
              url: "/selectnewassessment",
              to: "/selectnewassessment",
              tobackup: "/selectnewassessment",
              addLinkClass: "haf-navbtn-new new-assessment",
              fontIcon: "fas fa-plus",
              // attributes: { disabled: false },
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "My Assessments",
              url: "/myassessments",
              to: "/myassessments",
              tobackup: "/myassessments",
              fontIcon: "fas fa-user",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "All Assessments",
              url: "/allassessments",
              to: "/allassessments",
              tobackup: "/allassessments",
              fontIcon: "fas fa-users",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "/setup",
              tobackup: "/setup",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Referral",
              url: "/referral",
              to: "/referral",
              tobackup: "/referral",
              fontIcon: "fas fa-share",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Getting Started",
              url: "/gettingstarted",
              to: "/gettingstarted",
              tobackup: "/gettingstarted",
              fontIcon: "icon-question",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
          ],
        },
      });
    case "SET_NAVIGATION_GUEST":
      return Object.assign({}, state, {
        navigation: {
          items: [
            {
              _tag: "CSidebarNavItem",
              name: "New Assessment",
              url: "/selectnewassessment",
              to: "/selectnewassessment",
              tobackup: "/selectnewassessment",
              addLinkClass: "haf-navbtn-new new-assessment",
              fontIcon: "fas fa-plus",
              // attributes: { disabled: false },
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "My Assessments",
              url: "/myassessments",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-user",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "All Assessments",
              url: "/allassessments",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-users",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Referral",
              url: "/referral",
              to: "/referral",
              tobackup: "/referral",
              fontIcon: "fas fa-share",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Getting Started",
              url: "/gettingstarted",
              to: "/gettingstarted",
              tobackup: "/gettingstarted",
              fontIcon: "icon-question",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
          ],
        },
      });

    case "SET_NAVIGATION_GLOBAL":
      return Object.assign({}, state, {
        navigation: {
          items: [
            {
              _tag: "CSidebarNavItem",
              name: "New Assessment",
              url: "/selectnewassessment",
              to: "/selectnewassessment",
              tobackup: "/selectnewassessment",
              fontIcon: "fas fa-plus",
              addLinkClass: "haf-navbtn-new new-assessment",
              // attributes: { disabled: false },
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "My Assessments",
              url: "/myassessments",
              to: "/myassessments",
              tobackup: "/myassessments",
              fontIcon: "fas fa-user",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "All Assessments",
              url: "/allassessments",
              to: "/allassessments",
              tobackup: "/allassessments",
              fontIcon: "fas fa-users",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "/setup",
              tobackup: "/setup",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Referral",
              url: "/referral",
              to: "/referral",
              tobackup: "/referral",
              fontIcon: "fas fa-share",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Getting Started",
              url: "/gettingstarted",
              to: "/gettingstarted",
              tobackup: "/gettingstarted",
              fontIcon: "icon-question",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            // {
            //   _tag: "CSidebarNavTitle",
            //   _children: ["Admin Section"],
            // },
            {
              _tag: "CSidebarNavDropdown",
              name: "Global Admin",
              route: "/cashflow",
              fontIcon: "fas fa-user-tie",
              _children: [
                {
                  _tag: "CSidebarNavItem",
                  name: "Global Assessments",
                  url: "/globalassessments",
                  to: "/globalassessments",
                  tobackup: "/globalassessments",
                  // fontIcon: "fas fa-folder",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Credit & Subscription",
                  url: "/csmanagement",
                  to: "/csmanagement",
                  tobackup: "/csmanagement",
                  // fontIcon: "fas fa-credit-card",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Credits Dashboard",
                  url: "/cashflow",
                  to: "/cashflow",
                  tobackup: "/cashflow",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Rule List",
                  url: "/rulepage",
                  to: "/rulepage",
                  tobackup: "/rulepage",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Default Setup",
                  url: "/defaultsetup",
                  to: "/defaultsetup",
                  tobackup: "/defaultsetup",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                // {
                //   _tag: "CSidebarNavItem",
                //   name: "Transformation Rules",
                //   url: "/transformrules",
                //   to: "/transformrules",
                //   tobackup: "/transformrules",
                //   // fontIcon: "fa fa-tachometer",
                //   // attributes: { disabled: false },
                //   addLinkClass: "",
                //   disabled: false,
                // },
              ],
            },
          ],
        },
      });
    //========================================================================================
    /*                                                                                      *
     *                          CHOOSE SETUP FROM CREDIT MANAGEMENT                         *
     *                                                                                      */
    //========================================================================================

    case "SET_NAVIGATION_GLOBAL_SETUP":
      return Object.assign({}, state, {
        navigation: {
          items: [
            {
              _tag: "CSidebarNavItem",
              name: "New Assessment",
              url: "/selectnewassessment",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-plus",
              addLinkClass: "haf-navbtn-new c-disabled",
              // attributes: { disabled: true },
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "My Assessments",
              url: "/myassessments",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-user",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "All Assessments",
              url: "/allassessments",
              to: "",
              tobackup: "",
              fontIcon: "fas fa-users",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Setup",
              url: "/setup",
              to: "/setup",
              tobackup: "/setup",
              fontIcon: "fas fa-wrench",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Referral",
              url: "/referral",
              to: "/referral",
              tobackup: "/referral",
              fontIcon: "fas fa-share",
              // attributes: { disabled: false },
              addLinkClass: "",
              disabled: false,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Getting Started",
              url: "/gettingstarted",
              to: "",
              tobackup: "",
              fontIcon: "icon-question",
              // attributes: { disabled: true },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Global Admin",
              url: "/cashflow",
              to: "",
              tobackup: "",
              fontIcon: "fa fa-tachometer",
              // attributes: { disabled: false },
              addLinkClass: "c-disabled",
              disabled: true,
            },
            // {
            //   _tag: "CSidebarNavTitle",
            //   _children: ["Admin Section"],
            // },
            {
              _tag: "CSidebarNavDropdown",
              name: "Global Admin",
              route: "/cashflow",
              fontIcon: "fas fa-user-tie",
              _children: [
                {
                  _tag: "CSidebarNavItem",
                  name: "Global Assessments",
                  url: "/globalassessments",
                  to: "",
                  tobackup: "",
                  // fontIcon: "fas fa-folder",
                  // attributes: { disabled: false },
                  addLinkClass: "c-disabled",
                  disabled: true,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Credit & Subscription",
                  url: "/csmanagement",
                  to: "",
                  tobackup: "",
                  // fontIcon: "fas fa-credit-card",
                  // attributes: { disabled: false },
                  addLinkClass: "c-disabled",
                  disabled: true,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Credits Dashboard",
                  url: "/cashflow",
                  to: "",
                  tobackup: "",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "c-disabled",
                  disabled: true,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Rule List",
                  url: "/rulepage",
                  to: "/rulepage",
                  tobackup: "/rulepage",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                {
                  _tag: "CSidebarNavItem",
                  name: "Default Setup",
                  url: "/defaultsetup",
                  to: "/defaultsetup",
                  tobackup: "/defaultsetup",
                  // fontIcon: "fa fa-tachometer",
                  // attributes: { disabled: false },
                  addLinkClass: "",
                  disabled: false,
                },
                // {
                //   _tag: "CSidebarNavItem",
                //   name: "Transformation Rules",
                //   url: "/transformrules",
                //   to: "/transformrules",
                //   tobackup: "/transformrules",
                //   // fontIcon: "fa fa-tachometer",
                //   // attributes: { disabled: false },
                //   addLinkClass: "",
                //   disabled: false,
                // },
              ],
            },
          ],
        },
      });

    case "SET_ATTR_START_NEW_ASSESSMENT":
      return Object.assign({}, state, {
        navigation: {
          ...state.navigation.items,
          items: state.navigation.items.map((el) =>
            el.name === "New Assessment"
              ? {
                  ...el,
                  // attributes: { disabled: action.payload },
                  addLinkClass:
                    action.payload === true
                      ? el.addLinkClass.addClass("c-disabled")
                      : el.addLinkClass.delClass("c-disabled"),
                  disabled: action.payload,
                  to: action.payload === true ? "" : el.tobackup,
                }
              : el
          ),
        },
      });

    case "SET_ATTR_FOR_ALL_MENU":
      return Object.assign({}, state, {
        navigation: {
          ...state.navigation.items,
          items: state.navigation.items.map((el) => {
            if (el.hasOwnProperty("_children")) {
              // console.log(el._children.map((el1) => ({
              //   ...el1,
              //   addLinkClass:
              //     action.payload === true
              //       ? el1.addLinkClass.addClass("c-disabled")
              //       : el1.addLinkClass.delClass("c-disabled"),
              //   disabled: action.payload,
              //   to: action.payload === true ? "" : el1.tobackup,
              // })))
              return {
                ...el,
                _children: el._children.map((el1) => ({
                  ...el1,
                  addLinkClass:
                    action.payload === true
                      ? el1.addLinkClass.addClass("c-disabled")
                      : el1.addLinkClass.delClass("c-disabled"),
                  disabled: action.payload,
                  to: action.payload === true ? "" : el1.tobackup,
                })),
              };
            } else {
              return {
                ...el,
                addLinkClass:
                  action.payload === true
                    ? el.addLinkClass.addClass("c-disabled")
                    : el.addLinkClass.delClass("c-disabled"),
                disabled: action.payload,
                to: action.payload === true ? "" : el.tobackup,
              };
            }
          }),
        },
      });

    case "NEXT_ASSESSMENT_PAGE":
      const currentAssPage = state.assessmentPage;
      return Object.assign({}, state, {
        assessmentPage: currentAssPage + action.payload,
      });

    case "CLEAR_MYASSESSMENT1": {
      let tmp = Object.assign({}, state, {
        myAssessmentArr: []
      });
      return Object.assign({}, tmp);
    }

    case "LOAD_MYASSESSMENT1": {
      let tmp = Object.assign({}, state, {
        myAssessmentArr: action.payload.map((el) => {
          let new_str = "";
          let num =
            typeof el.email_client === "undefined" ? 0 : el.email_client.length;
          while (num-- > 0) new_str += "•";
          return {
            ...el,
            email_client: new_str,
            email_client_repeat: new_str,
            email_client_onblur: el.email_client,
            email_client_repeat_onblur: el.email_client,
          };
        }),
      });
      return Object.assign({}, tmp);
    }

    case "LOAD_MYASSESSMENTS_ALL_COUNT":
      return Object.assign({}, state, { totalMyAssessments: action.payload });
    case "LOAD_PAGE_MYASSESSMENT_COUNT":
      return Object.assign({}, state, {
        pagesCountMyAssessment: action.payload,
      });
    case "SET_CURRENT_MYASSESSMENT_PAGE":
      return Object.assign({}, state, {
        currentPageMyAssessment: action.payload,
      });
    case "INIT_MYASSESSMENT_PAGINATION":
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesMyAssessment,
        state.pagesCountMyAssessment
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesMyAssessments: arr });
    case "SET_VIS_MYASSESMENT_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesMyAssessment,
        state.pagesCountMyAssessment
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountMyAssessment - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesMyAssessments: arr });
    }
    case "LOAD_MESSAGES1": {
      return Object.assign({}, state, { allMessagesArr: action.payload });
    }
    case "COUNT_NEW_MESSAGES": {
      return Object.assign({}, state, { countNewMsg: action.payload });
    }
    case "NEW_MESSAGE_INPUT_CHANGE": {
      return Object.assign({}, state, {
        newMessage: { ...state.newMessage, msg: action.payload },
      });
    }
    case "SEARCH_ASSESSMENT_TYPE": {
      return Object.assign({}, state, { searchAssessment: action.payload });
    }
    case "SELECT_FIELD_SEARCH_ASSESSMENT": {
      return Object.assign({}, state, { fieldAssessment: action.payload });
    }
    case "SET_ERROR_STATE": {
      return Object.assign({}, state, { hasError: action.payload });
    }
    case "SET_ASSESSMENT_READY_STATUS": {
      return Object.assign({}, state, {
        assessmentReadyStatus: action.payload,
      });
    }
    case "OPEN_PREVIEW": {
      return Object.assign({}, state, { previewConfirm: action.payload });
    }
    case "OPEN_PREVIEW_TP": {
      return Object.assign({}, state, { previewConfirmTp: action.payload });
    }
    case "OPEN_PREVIEW_ASI" : {
      return Object.assign({}, state, { previewConfirmAsi: action.payload });
    }
    case "OPEN_PREVIEW_OBJ_ASI" : {
      return Object.assign({}, state, {previewConfirmObjAsi:{...state.previewConfirmObjAsi,
         isOpen: action.payload.isOpen,
         content: action.payload.content
      }});
    }
    case "OPEN_PREVIEW_REPORTS" : {
      return Object.assign({}, state, { previewConfirmCommon: action.payload });
    }
    case "PUT_PREVIEW_ASSESSMENT": {
      return Object.assign({}, state, { htmlPreview: action.payload });
    }
    case "PUT_PREVIEW_ASI": {
      return Object.assign({}, state, { htmlPreviewAsi: action.payload });
    }
    // case "OPEN_MODAL_ASSESSMENTLIST": {
    //   return Object.assign({}, state, { modalAssessmentList: action.payload });
    // }
    // case "OPEN_MODAL_ASSESSMENTLISTORIG": {
    //   return Object.assign({}, state, {
    //     modalAssessmentListOrig: action.payload,
    //   });
    // }
    case "OPEN_MODAL_MODALREMOTESESSION": {
      return Object.assign({}, state, { modalRemoteSession: action.payload });
    }
    case "ADD_TEXT_REMOTESESSION_INPUT_CHANGE": {
      return Object.assign({}, state, { addTextRemoteSession: action.payload });
    }
    case "LOAD_MODAL_ASSESSMENT_OBJ": {
      return Object.assign({}, state, { modalAssessmentObj: action.payload });
    }
    case "NEED_REFRESH_ASSESSMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshAssessmentList: !state.needRefreshAssessmentList,
      });
    }
    case "NEED_REFRESH_ASSESSMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshAssessmentListAndFirstPage:
          !state.needRefreshAssessmentListAndFirstPage,
      });
    }
    case "CONFIRM_YESNO": {
      return Object.assign({}, state, { confimYesNo: action.payload });
    }
    case "CONFIRM_YESNO_RECOMMEDATION": {
      return Object.assign({}, state, { confimYesNoRecommedation: action.payload });
    }
    case "CONFIRM_YESNO_ASI": {
      return Object.assign({}, state, { confimYesNo: action.payload, haf_or_asi: "ASI" });
    }
    case "CONFIRM_YESNO_HAF": {
      return Object.assign({}, state, { confimYesNo: action.payload, haf_or_asi: "HAF" });
    }
    case "CONFIRM_YESNO_NEW_ASSESSMENT": {
      return Object.assign({}, state, { confirmYesNoNewAssessment: action.payload });
    }
    case "CONFIRM_YESNO_NEW_ASSESSMENT2": {
      // return Object.assign({}, state, { confirmYesNoNewAssessment2: action.payload });
      return Object.assign({}, state, {confirmYesNoNewAssessment2: {
          ...state.confirmYesNoNewAssessment2,
          status: action.payload.status,
          buttonPath: action.payload.buttonPath,
        },
      });
    }
    case "CONFIRM_YESNO_USER": {
      return Object.assign({}, state, { confimYesNoUser: action.payload });
    }
    case "CONFIRM_YESNO_FILE": {
      return Object.assign({}, state, { confimYesNoFile: action.payload });
    }
    // case "CONFIRM_YESNO_PAYMENT": {
    //   return Object.assign({}, state, { confimYesNoPayment: action.payload });
    // }
    // case "CONFIRM_YESNO_PAYMENT_MSG": {
    //   return Object.assign({}, state, { confimYesNoPaymentMsg: action.payload });
    // }
    // case "CONTINUE_PAYMENT_PROCESS": {
    //   return Object.assign({}, state, { continuePaymentProcess: action.payload });
    // }
    case "SPECIFY_CLEARUNDO": {
      return Object.assign({}, state, { clearundo: action.payload });
    }
    case "ASSESSMENT_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.assessmentFilter;
      valueTmp = objectTmp.value;
      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      return Object.assign({}, state, {
        assessmentFilter: {
          ...state.assessmentFilter,
          value: valueTmp.map((el) => el),
        },
      });
    }
    case "SET_ERROR_TOKEN": {
      return Object.assign({}, state, {
        hasErrorToken: {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
        },
      });
    }
    case "LOAD_MODAL_USER_OBJ": {
      return Object.assign({}, state, { modalUserObj: action.payload });
    }
    case "BLUR_EMAIL_RECEPIENT_INPUT_CHANGE": {
      console.log("state.newCredit.card_email=", state.newCredit.card_email);
      state.newCredit.card_email = state.newCredit.card_email.replace(
        /^\s+|\s+$/g,
        ""
      );
      return Object.assign({}, state, {
        newCredit: {
          ...state.newCredit,
          card_email: state.newCredit.card_email,
        },
      });
    }
    case "OPEN_MODAL_CONFIRM_PAYMENT": {
      return Object.assign({}, state, { confimPayment: action.payload });
    }
    case "AGREE_TERM_OF_SERVICE": {
      return Object.assign({}, state, {
        agreeTermOfService: !state.agreeTermOfService,
      });
    }
    case "STATUS_AGREE_TERM_OF_SERVICE": {
      return Object.assign({}, state, { agreeTermOfService: action.payload });
    }
    case "SET_CHOOSED_ASSESSMENT_ID": {
      return Object.assign({}, state, { choosedAssessmentId: action.payload });
    }
    case "PERIOD_FILTER_INPUT_CHANGE": {
      return Object.assign({}, state, { selectedPeriodFilter: action.payload });
    }
    case "BACKUP_SETUP_ORG": {
      return Object.assign({}, state, {
        setupOrgBackup: { ...state.setupOrg },
      });
    }
    case "BACKUP_SETUP_USER": {
      return Object.assign({}, state, {
        setupUserBackup: { ...state.setupUser },
      });
    }
    case "RESTORE_SETUP_ORG": {
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrgBackup },
      });
    }
    case "RESTORE_SETUP_USER": {
      return Object.assign({}, state, {
        setupUser: { ...state.setupUserBackup },
      });
    }
    case "SET_COMPANY_ID": {
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, CompanyId: action.payload },
      });
    }
    case "OPEN_LOGIN_INACTIVE": {
      return Object.assign({}, state, { tryLoginInactive: action.payload });
    }
    case "SET_SHOWGETTINGSTARTED":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, showgettingstarted: action.payload },
      });
    case "SET_SHOWTOUR":
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, showtour: action.payload },
      });
    //========================================================================================
    /*                                                                                      *
     *                               EVENT CREDIT MANAGEMENT                              *
     *                                                                                      */
    //========================================================================================

    case "SELECT_FIELD_SEARCH_CREDITMANAGEMENT": {
      return Object.assign({}, state, {
        fieldCreditManagement: action.payload,
      });
    }
    case "SEARCH_CREDITMANAGEMENT_TYPE": {
      return Object.assign({}, state, {
        searchCreditManagement: action.payload,
      });
    }
    case "PERIOD_FILTER_CREDITMANAGEMENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        selectedPeriodFilterCreditManagement: action.payload,
      });
    }
    case "NEED_REFRESH_CREDITMANAGEMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshCreditManagementList: !state.needRefreshCreditManagementList,
      });
    }
    case "NEED_REFRESH_CREDITMANAGEMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshCreditManagementListAndFirstPage:
          !state.needRefreshCreditManagementListAndFirstPage,
      });
    }

    //========================================================================================
    /*                                                                                      *
     *                               EVENT CASHFLOW MANAGEMENT                              *
     *                                                                                      */
    //========================================================================================

    case "SELECT_FIELD_SEARCH_CASHFLOWMANAGEMENT": {
      return Object.assign({}, state, {
        fieldCashflowManagement: action.payload,
      });
    }
    case "SEARCH_CASHFLOWMANAGEMENT_TYPE": {
      return Object.assign({}, state, {
        searchCashflowManagement: action.payload,
      });
    }
    case "PERIOD_FILTER_CASHFLOWMANAGEMENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        selectedPeriodFilterCashflowManagement: action.payload,
      });
    }
    case "NEED_REFRESH_CASHFLOWMANAGEMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshCashflowManagementList:
          !state.needRefreshCashflowManagementList,
      });
    }
    case "NEED_REFRESH_CASHFLOWMANAGEMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshCashflowManagementListAndFirstPage:
          !state.needRefreshCashflowManagementListAndFirstPage,
      });
    }
//========================================================================================
    /*                                                                                      *
     *                               EVENT SUBSCRIPTION MANAGEMENT                              *
     *                                                                                      */
    //========================================================================================
    case "SELECT_FIELD_SEARCH_SUBSCRIPTIONMANAGEMENT": {
      return Object.assign({}, state, {
        fieldSubscriptionManagement: action.payload,
      });
    }
    case "SEARCH_SUBSCRIPTIONMANAGEMENT_TYPE": {
      return Object.assign({}, state, {
        searchSubscriptionManagement: action.payload,
      });
    }
    case "PERIOD_FILTER_SUBSCRIPTIONMANAGEMENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        selectedPeriodFilterSubscriptionManagement: action.payload,
      });
    }
    case "NEED_REFRESH_SUBSCRIPTIONMANAGEMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshSubscriptionManagementList: !state.needRefreshSubscriptionManagementList,
      });
    }
    case "NEED_REFRESH_SUBSCRIPTIONMANAGEMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshSubscriptionManagementListAndFirstPage:
          !state.needRefreshSubscriptionManagementListAndFirstPage,
      });
    }
//---------------------------------------------------------------------

//========================================================================================
    /*                                                                                      *
     *                               EVENT CONTRACT MANAGEMENT                              *
     *                                                                                      */
    //========================================================================================
    case "SELECT_FIELD_SEARCH_CONTRACTMANAGEMENT": {
      return Object.assign({}, state, {
        fieldContractManagement: action.payload,
      });
    }
    case "SEARCH_CONTRACTMANAGEMENT_TYPE": {
      return Object.assign({}, state, {
        searchContractManagement: action.payload,
      });
    }
    case "PERIOD_FILTER_CONTRACTMANAGEMENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        selectedPeriodFilterContractManagement: action.payload,
      });
    }
    case "NEED_REFRESH_CONTRACTMANAGEMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshContractManagementList: !state.needRefreshContractManagementList,
      });
    }
    case "NEED_REFRESH_CONTRACTMANAGEMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshContractManagementListAndFirstPage:
          !state.needRefreshContractManagementListAndFirstPage,
      });
    }

    //========================================================================================
    /*                                                                                      *
     *                               EVENT CREDIT MANAGEMENT                              *
     *                                                                                      */
    //========================================================================================

    case "SELECT_FIELD_SEARCH_PROPAMANAGEMENT": {
      return Object.assign({}, state, {
        fieldPropaManagement: action.payload,
      });
    }
    case "SEARCH_PROPAMANAGEMENT_TYPE": {
      return Object.assign({}, state, {
        searchPropaManagement: action.payload,
      });
    }
    case "PERIOD_FILTER_PROPAMANAGEMENT_INPUT_CHANGE": {
      return Object.assign({}, state, {
        selectedPeriodFilterPropaManagement: action.payload,
      });
    }
    case "NEED_REFRESH_PROPAMANAGEMENT_LIST": {
      return Object.assign({}, state, {
        needRefreshPropaManagementList: !state.needRefreshPropaManagementList,
      });
    }
    case "NEED_REFRESH_PROPAMANAGEMENT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshPropaManagementListAndFirstPage:
          !state.needRefreshPropaManagementListAndFirstPage,
      });
    }
//---------------------------------------------------------------------



    case "SELECT_FIELD_SEARCH_HISTORIES": {
      return Object.assign({}, state, { fieldHistories: action.payload });
    }
    case "SEARCH_HISTORIES_TYPE": {
      return Object.assign({}, state, { searchHistories: action.payload });
    }
    case "LOAD_HISTORIESEMAILANDID": {
      const emptyItem = [{ id: 0, email: "All", value: "*" }];
      const listEmails = action.payload.map((el) => {
        return { id: el.id, email: el.email, value: el.id.toString() };
      });
      listEmails.sort((a, b) => {
        if (a.email > b.email) {
          return 1;
        }
        if (a.email < b.email) {
          return -1;
        }
      });
      // console.log("listEmails=",listEmails);
      listEmails.forEach((el) => {
        emptyItem.push({ id: el.id, email: el.email, value: el.id.toString() });
      });
      return Object.assign({}, state, { eventEmailAndId: emptyItem });
    }
    case "SEARCH_HISTORIES_EMAILANDID": {
      return Object.assign({}, state, {
        searchHistoriesEmailAndId: action.payload,
      });
    }
    case "SET_SEARCHHISTORIESTO": {
      return Object.assign({}, state, { searchHistoriesTo: action.payload });
    }
    case "SET_SEARCHHISTORIESFROM": {
      return Object.assign({}, state, { searchHistoriesFrom: action.payload });
    }
    case "CHANGE_FIELD_ASSESSMENT": {
      return Object.assign({}, state, { fieldAssessment: action.payload });
    }
    case "CHANGE_TYPE_ASSESSMENT": {
      return Object.assign({}, state, { changeTypeAssessment: action.payload });
    }
    case "CHANGE_TYPE_ASSESSMENT_ASI": {
      return Object.assign({}, state, { changeTypeAssessmentAsi: action.payload });
    }
    case "SET_SIDEBAR_SHOW": {
      const val = [true, "responsive"].includes(action.payload)
        ? false
        : "responsive";
      return Object.assign({}, state, { sidebarShow: val });
    }
    case "SET_SIDEBAR_MOBILE_SHOW": {
      const val = [false, "responsive"].includes(action.payload)
        ? true
        : "responsive";
      return Object.assign({}, state, { sidebarShow: val });
    }
    case "LOAD_MYASSESSMENTS_TYPE_COUNT":
      // console.log(action.payload.type," ",)
      switch (action.payload.type) {
        case Constants.ASSESSMENT_NEW:
          return Object.assign({}, state, {
            newMyAssessments: action.payload.count,
          });
        case Constants.ASSESSMENT_SAVED:
          return Object.assign({}, state, {
            notcompletedMyAssessments: action.payload.count,
          });
        case Constants.ASSESSMENT_COMPLETED:
          return Object.assign({}, state, {
            completedMyAssessments: action.payload.count,
          });
        default:
          return state;
      }
    case "TAKE_ITEM_ORGANIZATION":
      return Object.assign({}, state, {
        organizationForDelete: {
          ...state.organizationForDelete,
          CompanyId: action.payload.CompanyId,
          organization: action.payload.organization,
        },
      });
    case "SET_ACTIVE_SETUP_TAB": {
      return Object.assign({}, state, { setupActiveTab: action.payload });
    }
    case "SET_ACTIVE_SUBSCRIBE_COLOR": {
      return Object.assign({}, state, { colorSubscribe: action.payload });
    }
    case "SET_ACTIVE_CREDIT_COLOR": {
      return Object.assign({}, state, { colorCredit: action.payload });
    }
    // case "CASHFLOW_SORT": {
    //   return Object.assign({}, state, {
    //     cashflowSortParam:[
    //     ...state.cashflowSortParam,
    //     {
    //       param_sort:action.payload.param_sort,
    //       param_up_down: action.payload.param_up_down
    //     }
    //   ]
    //   });
    // }
    case "CASHFLOW_SORT": {
      // const cashflowSortParamStore = store.getState().evtreducer.cashflowSortParam;
      // console.log("cashflowSortParamStore.find(el=>el.param_up_down===Param_sort",cashflowSortParamStore.find(el=>el.param_sort===Param_sort))
      const oldParamSort = state.cashflowSortParam.find(
        (el) => el.param_up_down !== ""
      ).param_sort; //c
      // console.log("oldParamSort=",oldParamSort);
      if (
        oldParamSort === action.payload.param_sort &&
        action.payload.param_up_down === ""
      ) {
        return state;
      } else {
        return Object.assign({}, state, {
          cashflowSortParam: state.cashflowSortParam.map((el) => {
            if (el.param_sort === action.payload.param_sort) {
              return { ...el, param_up_down: action.payload.param_up_down };
            } else return { ...el, param_up_down: "" };
          }),
        });
      }
    }
    case "OPEN_MODAL_NOTIFICATION": {
      return Object.assign({}, state, { modalNotification: action.payload });
    }
    case "WRITE_PARAM_NOTIFICATION": {
      return Object.assign({}, state, {
        paramNotification: {
          ...state.paramNotification,
          type: action.payload.type,
          CompanyId: action.payload.CompanyId,
          to: action.payload.to,
          subject: action.payload.subject,
          // title:action.payload.title,
          message: action.payload.message,
        },
      });
    }
    case "SUBJECT_NOTIFICATION_INPUT_CHANGE": {
      return Object.assign({}, state, {
        paramNotification: {
          ...state.paramNotification,
          subject: action.payload,
        },
      });
    }
    // case "TITLE_NOTIFICATION_INPUT_CHANGE": {
    //   return Object.assign({}, state, {paramNotification: {
    //     ...state.paramNotification,
    //     title:action.payload,
    //   } });
    // }
    case "MESSAGE_NOTIFICATION_INPUT_CHANGE": {
      return Object.assign({}, state, {
        paramNotification: {
          ...state.paramNotification,
          message: action.payload,
        },
      });
    }
    case "CREDIT_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.creditsFilter;
      valueTmp = objectTmp.value;
      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      return Object.assign({}, state, {
        creditsFilter: {
          ...state.creditsFilter,
          value: valueTmp.map((el) => el),
        },
      });
    }
    case "NEED_REFRESH_CREDIT_LIST_FIRST_PAGE": {
      return Object.assign({}, state, {
        needRefreshCreditListAndFirstPage:
          !state.needRefreshCreditListAndFirstPage,
      });
    }
    case "PAYMENT_PERFORMANCE_SET_MONTH":
      return Object.assign({}, state, { findMonthPerf: action.payload });
    case "PAYMENT_PERFORMANCE_SET_YEAR":
      return Object.assign({}, state, { findYearPerf: action.payload });
    case "PAYMENT_PERFORMANCE_LOAD_MONTH": {
      let tmpArr = Object.assign([], action.payload);
      let sum_incoming = tmpArr.reduce((sum, el) => {
        return sum + parseFloat(el.incoming);
      }, 0);
      let sum_incoming_sum = tmpArr.reduce((sum, el) => {
        return sum + parseFloat(el.incoming_sum);
      }, 0);
      tmpArr.push({
        CompanyId: "",
        credit_date: "",
        incoming: sum_incoming,
        incoming_sum: sum_incoming_sum,
        organization: "SUM :",
      });
      return Object.assign({}, state, { perfMonthArr: tmpArr });
    }
    case "PAYMENT_PERFORMANCE_LOAD_YEAR": {
      let tmpArr = Object.assign([], action.payload);
      let sum_incoming = tmpArr.reduce((sum, el) => {
        return sum + parseFloat(el.incoming);
      }, 0);
      let sum_outcoming = tmpArr.reduce((sum, el) => {
        return sum + parseFloat(el.outcoming);
      }, 0);
      tmpArr.push({
        month: "SUM :",
        outcoming: sum_outcoming,
        incoming: sum_incoming,
      });
      return Object.assign({}, state, { perfYearArr: tmpArr });
    }
    case "REFRESH_TABLE_PERF_MONTH_ARROR_SPINNER" : {
      return Object.assign({}, state, { refreshTablePerfMonthArrORspinner: action.payload });
    }
    case "REFRESH_TABLE_PERF_YEAR_ARROR_SPINNER": {
      return Object.assign({}, state, { refreshTablePerfYearArrORspinner: action.payload });
    }
    case "REFRESH_TABLE_SSTUP_COMPANY_CASHFLOW_ARR_OR_SPINNER": {
      return Object.assign({}, state, { refreshTablesStupCompanyCashflowArrORspinner: action.payload });
    }
    //========================================================================================
    /*                                                                                      *
     *                   LOAD FILTERED DATA TO resultCodeDisorderArrMass                   *
     *                                                                                      */
    //========================================================================================

    case "LOAD_CODE_DISORDER_MASS": {
      let tmpArr = action.payload.filter((el) => el.code !== "");
      return Object.assign({}, state, { resultCodeDisorderArrMass: tmpArr });
    }
    //========================================================================================
    /*                                                                                      *
     *                   LOAD FILTERED DATA TO resultTitleDisorderArrMass                   *
     *                                                                                      */
    //========================================================================================

    case "LOAD_TITLE_DISORDER_MASS": {
      let tmpArr = action.payload.filter((el) => el.title !== "");
      return Object.assign({}, state, { resultTitleDisorderArrMass: tmpArr });
    }
    //========================================================================================
    /*                                                                                      *
     *                          LOAD DATA FROM BASE TABLE DISORDER                          *
     *                                                                                      */
    //========================================================================================
    case "LOAD_DISORDER_MASS": {
      return Object.assign({}, state, { disorderMass: action.payload });
    }
    //========================================================================================
    /*                                                                                      *
     *                                      ACTIVE CODE                                     *
     *                                                                                      */
    //========================================================================================

    // case "SET_FOCUS_CODE_DISORDER_MASS" : {
    //   return Object.assign({}, state, { activeElCodeTitle: action.payload });
    // }
    //========================================================================================
    /*                                                                                      *
     *                                     ACTIVE TITLE                                     *
     *                                                                                      */
    //========================================================================================

    // case "SET_FOCUS_TITLE_DISORDER_MASS" : {
    //   return Object.assign({}, state, { activeElCodeTitle: action.payload });
    // }

    case "SET_FOCUS_EL_DISORDER_MASS": {
      return Object.assign({}, state, { activeElCodeTitle: action.payload });
    }
    //========================================================================================
    /*                                                                                      *
     *               SET CODES TO idOfRecCode - NOT NULL MEANS FILL CORRECTLY               *
     *                                                                                      */
    //========================================================================================
    case "SET_FOCUS_IDOFRECCODE": {
      // console.log(action.payload);
      let tmpObj = Object.assign({}, state, {
        idOfRecCode: {
          ...state.idOfRecCode,
          [action.payload.act]: action.payload.id,
        },
      });
      // console.log("-------FOCUS CODE---------")
      // console.log("idOfRecCode=",tmpObj.idOfRecCode)
      // console.log("idOfRecTitle=",tmpObj.idOfRecTitle)
      return tmpObj;
    }
    case "SET_FOCUS_IDOFRECTITLE": {
      // console.log(action.payload);
      let tmpObj = Object.assign({}, state, {
        idOfRecTitle: {
          ...state.idOfRecTitle,
          [action.payload.act]: action.payload.id,
        },
      });
      // console.log("-------FOCUS TITLE---------")
      // console.log("idOfRecCode=",tmpObj.idOfRecCode)
      // console.log("idOfRecTitle=",tmpObj.idOfRecTitle)
      return tmpObj;
    }
    case "SET_TOOLTIP_TEXT": {
      return Object.assign({}, state, { toolTipDisorder: action.payload });
    }
    case "SET_RULE_SECTION": {
      return Object.assign({}, state, { ruleSection: action.payload.section });
    }
    case "SET_RULE_ID": {
      return Object.assign({}, state, {
        ruleId: { id: action.payload.id, shortname: action.payload.shortname },
      });
    }
    case "SET_RULE_RESULT": {
      // console.log("state.ruleResult=",state.ruleResult);
      // return Object.assign({}, state, { ruleResult: action.payload.result, ruleResultPos: action.payload.position});
      return Object.assign({}, state, { ruleResult: action.payload });
    }
    case "SET_RULE_RESULT_POSITION": {
      // console.log("state.ruleResult=",state.ruleResult);
      // return Object.assign({}, state, { ruleResult: action.payload.result, ruleResultPos: action.payload.position});
      return Object.assign({}, state, { ruleResultPos: action.payload });
    }
    case "SET_RULE_NAME": {
      return Object.assign({}, state, { ruleName: action.payload });
    }

    case "SET_NEW_RULE_PREDICATE": {
      return Object.assign({}, state, {
        newRulePredicate: {
          ...state.newRulePredicate,
          idKey: action.payload.idKey,
          id: action.payload.id,
          shortname: action.payload.shortname,
          title: action.payload.title,
          type: action.payload.type,
          scopeOperations: action.payload.scopeOperations,
          operation: action.payload.operation,
          scopeValues: action.payload.scopeValues,
          value: action.payload.value,
        },
      });
    }
    case "ADD_RULE_PREDICATE": {
      // return { ...state, posts: state.posts.concat([action.payload]) };
      // return { ...state, posts: [...state.posts, action.payload] };
      return Object.assign({}, state, {
        newRulePredicateMas: [...state.newRulePredicateMas, action.payload],
      });
    }
    case "SET_ARRAY_RULE_PREDICATES": {
      return Object.assign({}, state, {
        newRulePredicateMas: action.payload,
      });
    }
    case "SET_SCOPE_OPERATIONS": {
      return Object.assign({}, state, {
        newRulePredicateMas: state.newRulePredicateMas.map((el) =>
          el.idKey === action.payload.idKey
            ? {
                ...el,
                operation: action.payload.operation,
              }
            : el
        ),
      });
    }
    case "SET_SCOPE_VALUES": {
      return Object.assign({}, state, {
        newRulePredicateMas: state.newRulePredicateMas.map((el) =>
          el.idKey === action.payload.idKey
            ? {
                ...el,
                value: action.payload.value,
              }
            : el
        ),
      });
    }
    case "CLEAR_RULE_PREDICATE": {
      return Object.assign({}, state, {
        newRulePredicateMas: [],
        ruleResult: "",
        ruleName: "",
        ruleResultPos: 0,
        rulePlaceholder: "",
        newRule: {
          id: 0,
          CompanyId: 0,
          UserId: 0,
          created: "",
          updated: "",
          rule_window: "",
          rule_body: "",
          result: "",
          rulename: "",
          subs: 1,
          mh: 1,
          cooc: 1,
          adult_type: "",
          priority: 1,
        },
        assessmentTypeFilter: {
          name: "Assessment Type :",
          prevalue: "Assessment Type :",
          disabled: "false",
          type: "checkbox",
          value: [true, true, true],
          radio_options: ["SUBS", "MH", "COOC"],
        },
      });
    }
    case "CLEAR_RULE_DIALOG": {
      return Object.assign({}, state, {
        newRulePredicateMas: [],
        ruleResult: "",
        ruleName: "",
        ruleResultPos: 0,
        rulePlaceholder: "",
        newRule: {
          ...state.newRule,
          updated: "",
          rule_window: "",
          rule_body: "",
          result: "",
          rulename: "",
          subs: 1,
          mh: 1,
          cooc: 1,
          adult_type: "Adult",
        },
        assessmentTypeFilter: {
          name: "Assessment Type :",
          prevalue: "Assessment Type :",
          disabled: "false",
          type: "checkbox",
          value: [true, true, true],
          radio_options: ["SUBS", "MH", "COOC"],
        },
      });
    }
    case "LOAD_RULES1":
      let tmpObj = action.payload.data.map((el) => {
        return {
          ...el,
          checked: action.payload.ruleCheckedId.includes(el.id) ? true : false,
        };
      });
      return (tmpObj = Object.assign({}, state, { ruleListArr: tmpObj }));

    case "LOAD_RULES_ALL_COUNT": {
      return Object.assign({}, state, { totalRules: action.payload });
    }
    case "LOAD_PAGE_RULE_COUNT": {
      return Object.assign({}, state, { pagesCountRule: action.payload });
    }
    case "SET_CURRENT_RULE_PAGE": {
      return Object.assign({}, state, { currentPageRule: action.payload });
    }
    case "INIT_RULE_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesRule,
        state.pagesCountRule
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesRule: arr });
    }

    case "SET_VIS_RULE_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesRule,
        state.pagesCountRule
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountRule - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesRule: arr });
    }

    case "OPEN_NEW_RULE_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlDialog.slice(
          0,
          state.controlDialog.length - 1
        ); //remove last element
        // console.log("tmp=",tmp)
        return Object.assign({}, state, {
          dialogOpenRule: action.payload,
          controlDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenRule: action.payload });
      }

    case "OPEN_NEW_TESTING_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlDialog.slice(
          0,
          state.controlDialog.length - 1
        ); //remove last element
        // console.log("tmp=",tmp)
        return Object.assign({}, state, {
          dialogOpenTesting: action.payload,
          controlDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenTesting: action.payload });
      }

    case "NEED_REFRESH_RULE_LIST": {
      return Object.assign({}, state, {
        needRefresRuleList: !state.needRefresRuleList,
      });
    }
    case "TAKE_ITEM_RULE":

      return Object.assign({}, state, {
        newRule: {
          ...state.newRule,
          id: action.payload.id,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          created: action.payload.created,
          updated: action.payload.updated,
          rule_window: action.payload.rule_window,
          rule_body: action.payload.rule_body,
          result: action.payload.result,
          rulename: action.payload.rulename,
          subs: action.payload.subs,
          mh: action.payload.mh,
          cooc: action.payload.cooc,
          adult_type: action.payload.adult_type,
          priority: action.payload.priority,
        },
      });
    case "SET_CONTROL_STATUS_RULE_DIALOG": {
      const stage =
        action.payload === "Testing" &&
        state.controlDialog[state.controlDialog.length - 1] === "New"
          ? [
              ...state.controlDialog.slice(0, state.controlDialog.length - 1),
              "Update",
              action.payload,
            ]
          : [...state.controlDialog, action.payload];
      return Object.assign({}, state, {
        controlDialog: stage,
      });
    }

    case "SET_RULE_PLACEHOLDER_SECTION": {
      return Object.assign({}, state, { rulePlaceholder: action.payload });
    }
    case "SET_PLACEHOLDER_TO_RESULT": {
      return Object.assign({}, state, { ruleResult: action.payload });
    }

    case "TYPEOFASSESSMENT_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.assessmentTypeFilter;
      valueTmp = objectTmp.value;

      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      let tempArr = [];
      objectTmp.value.forEach((el, index) => {
        if (el === true) tempArr.push(objectTmp.radio_options[index]);
      });

      // console.log("tempArr=",tempArr)
      return Object.assign({}, state, {
        assessmentTypeFilter: {
          ...state.assessmentTypeFilter,
          value: valueTmp.map((el) => el),
        },
        newRule: {
          ...state.newRule,
          subs: tempArr.includes("SUBS") ? true : false,
          mh: tempArr.includes("MH") ? true : false,
          cooc: tempArr.includes("COOC") ? true : false,
        },
      });
    }
    case "SET_TYPEOFASSESSMENT": {
      objectTmp = state.assessmentTypeFilter;

      valueTmp = objectTmp.radio_options.map((el, index) => {
        switch (el) {
          case "SUBS":
            objectTmp.value[index] = action.payload.subs;
            break;
          case "MH":
            objectTmp.value[index] = action.payload.mh;
            break;
          case "COOC":
            objectTmp.value[index] = action.payload.cooc;
            break;
        }
        return objectTmp.value[index];
      });
      // console.log("valueTmp=",valueTmp)
      return Object.assign({}, state, {
        assessmentTypeFilter: {
          ...state.assessmentTypeFilter,
          value: valueTmp.map((el) => el),
        },
      });
    }
    case "SET_CHECK_FOR_MOVE": {
      const tmpObj = Object.assign({}, state, {
        ruleCheckedId: state.ruleListArr.find(
          (el) => el.id === action.payload.id
        ).checked
          ? state.ruleCheckedId.filter((el) => el !== action.payload.id)
          : [...state.ruleCheckedId, action.payload.id],
        ruleCheckedIdName: state.ruleListArr.find(
          (el) => el.id === action.payload.id
        ).checked
          ? state.ruleCheckedIdName.filter(
              (el) => el !== action.payload.ruleheckedIdName
            )
          : [
              ...state.ruleCheckedIdName,
              {
                rulename: action.payload.rulename,
                priority: action.payload.priority,
                page: action.payload.page,
              },
            ],
      });
      return tmpObj;
    }
    case "UPDATE_CHECKED_RULE_LIST": {
      const tmpObj = Object.assign({}, state, {
        ruleListArr: state.ruleListArr.map((el1) => {
          if (action.payload.includes(el1.id)) {
            return {
              ...el1,
              checked: true,
            };
          } else {
            return {
              ...el1,
              checked: false,
            };
          }
        }),
      });
      return tmpObj;
    }
    case "CLEAR_CHECKED_RULE_LIST": {
      const tmpObj = Object.assign({}, state, {
        ruleCheckedId: [],
        ruleCheckedIdName: [],
      });
      return tmpObj;
    }
    case "ENABLE_MOVE_RULE": {
      const tmpObj = Object.assign({}, state, {
        enableMoveRule: action.payload,
      });
      return tmpObj;
    }
    case "UNDO_SELECT_RULE": {
      const tmpObj = Object.assign({}, state, {
        ruleCheckedId: [],
        ruleCheckedIdName: [],
      });
      return tmpObj;
    }
    case "LOAD_MYASSESSMENT_TO_RULE": {
      return Object.assign({}, state, { myAssessmentArr: action.payload });
    }
    case "SET_TEST_RULE_ASSESSMENT": {
      return Object.assign({}, state, { ruleAssessment: action.payload });
    }
    case "SET_CLIENT_INFO_CONTENT": {
      const assessmentObj = action.payload.data.msg;
      let tmpObj = state.clientInfoContent.map((el) => {
        //fill client info card
        return { ...el, content: assessmentObj[el.key] };
      });
      //make array of id predicate ['DI.1','DI.2']

      const tmp = action.payload.predicateRecord
        .filter((el) => el.id !== "")
        .map((el1) => ({
          id: el1.id,
          title: el1.title,
          shortname: el1.shortname,
        }));
      const assessmentJson = JSON.parse(assessmentObj.assessment_json);
      tmp.forEach((eltmp, index, arr) => {
        tmpObj.push({
          id:
            Math.max.apply(
              null,
              tmpObj.map((el) => el.id)
            ) + 1,
          key: eltmp.id,
          title: `<b>${eltmp.title}</b>`,
          content: jsonShortElem(assessmentJson, eltmp.shortname),
        });
      });
      return Object.assign({}, state, {
        clientInfoContent: tmpObj,
        resultContent: action.payload.result,
      });
    }

    case "INIT_CLIENT_INFO_CONTENT": {
      return Object.assign({}, state, {
        clientInfoContent: patternClientInfoContent,
        resultContent: "",
      });
    }
    case "INIT_RULE_ASSESSMENT": {
      return Object.assign({}, state, { ruleAssessment: "" });
    }
    case "SET_EVENT_RULE": {
      return Object.assign({}, state, { eventRule: action.payload });
    }
    case "MAKE_RULE_ANIMATION":
      return Object.assign({}, state, { makeAnimationStatus: action.payload });
    case "NEED_REFRESH_QUILL_POSITION": {
      return Object.assign({}, state, {
        needRefresQuillPosition: !state.needRefresQuillPosition,
      });
    }
    case "SET_ACTIVE_RULE_TAB": {
      return Object.assign({}, state, { rulepromoActiveTab: action.payload });
    }
    case "SET_ACTIVE_CSMANAGEMENT_TAB": {
      return Object.assign({}, state, { csManagementActiveTab: action.payload });
    }
    case "OPEN_NEW_PROMO_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlPromoDialog.slice(
          0,
          state.controlPromoDialog.length - 1
        ); //remove last element
        return Object.assign({}, state, {
          dialogOpenPromo: action.payload,
          controlPromoDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenPromo: action.payload });
      }
    case "SET_CONTROL_STATUS_PROMO_DIALOG": {
      const stage =
        action.payload === "Testing" &&
        state.controlPromoDialog[state.controlPromoDialog.length - 1] === "New"
          ? [
              ...state.controlPromoDialog.slice(
                0,
                state.controlPromoDialog.length - 1
              ),
              "Update",
              action.payload,
            ]
          : [...state.controlPromoDialog, action.payload];
      return Object.assign({}, state, {
        controlPromoDialog: stage,
      });
    }
    case "CLEAR_PROMO": {
      return Object.assign({}, state, {
        newPromo: {
          id: 0,
          description: "",
          code: "",
          bonus: 0,
          created: "",
          expires: "",
          status: "",
        },
        promoExpiresFilter: {
          name: "Unlimited :",
          prevalue: "Unlimited :",
          disabled: "false",
          type: "checkbox",
          value: [false],
          radio_options: ["Unlimited"],
        },
      });
    }
    case "SET_PROMO_DESCRIPTION": {
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          description: action.payload,
        },
      });
    }
    case "SET_PROMO_CODE":
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          code: action.payload,
        },
      });
    case "SET_PROMO_BONUS":
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          bonus: action.payload,
        },
      });
    case "SET_PROMO_CREATED":
      // console.log(action.payload);
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          created: action.payload,
        },
      });
    case "SET_PROMO_EXPIRES":
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          expires: action.payload,
        },
      });
    case "LOAD_PROMOS1": {
      return Object.assign({}, state, { promoListArr: action.payload });
    }
    case "LOAD_PROMOS_ALL_COUNT": {
      return Object.assign({}, state, { totalPromos: action.payload });
    }
    case "LOAD_PAGE_PROMO_COUNT": {
      return Object.assign({}, state, { pagesCountPromo: action.payload });
    }
    case "SET_CURRENT_PROMO_PAGE": {
      return Object.assign({}, state, { currentPagePromo: action.payload });
    }
    case "INIT_PROMO_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesPromo,
        state.pagesCountPromo
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesPromo: arr });
    }

    case "SET_VIS_PROMO_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesPromo,
        state.pagesCountPromo
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountPromo - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesRule: arr });
    }
    case "NEED_REFRESH_PROMO_LIST": {
      return Object.assign({}, state, {
        needRefresPromoList: !state.needRefresPromoList,
      });
    }
    case "TAKE_ITEM_PROMO":
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          id: action.payload.id,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          description: action.payload.description,
          code: action.payload.code,
          bonus: parseFloat(action.payload.bonus).toFixed(),
          created: moment(action.payload.created).format("YYYY-MM-DD"),
          expires: action.payload.expires==="1970-01-01T00:00:00.000Z"?"Unlimited":moment(action.payload.expires).format("YYYY-MM-DD"),
          status: action.payload.status,
        },
        promoExpiresFilter: {
          ...state.promoExpiresFilter,
          value:
            moment(action.payload.expires).format("YYYY-MM-DD") === "1970-01-01"
              ? [true]
              : [false],
        },
      });
    case "SET_EXPIRES_PROMO": {
      return Object.assign({}, state, {
        newPromo: {
          ...state.newPromo,
          expires: moment().startOf("day").format("YYYY-MM-DD"),
          status: action.payload.status,
        },
        promoExpiresFilter: {
          ...state.promoExpiresFilter,
          value: [false],
        },
      });
    }
    case "PROMOEXPIRES_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.promoExpiresFilter;
      valueTmp = objectTmp.value;

      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      let tempArr = [];
      objectTmp.value.forEach((el, index) => {
        if (el === true) tempArr.push(objectTmp.radio_options[index]);
      });

      // console.log("tempArr=",tempArr)
      return Object.assign({}, state, {
        promoExpiresFilter: {
          ...state.promoExpiresFilter,
          value: valueTmp.map((el) => el),
        },
        newPromo: {
          ...state.newPromo,
          expires: moment(action.payload.expires).format("YYYY-MM-DD"),
          expires: tempArr.includes("Unlimited")
            ? "Unlimited"
            : moment().add(1, "year").startOf("hour").format("YYYY-MM-DD"),
        },
      });
    }
    case "CONFIRM_PROMO_YESNO": {
      return Object.assign({}, state, { confimPromoYesNo: action.payload });
    }
    case "SET_FIND_MONTH_PROMO_PERF": {
      return Object.assign({}, state, { findMonthPromoPerf: action.payload });
    }
    case "SET_FIND_YEAR_PROMO_PERF": {
      return Object.assign({}, state, { findYearPromoPerf: action.payload });
    }
    case "SET_CASHFLOW_PROMO": {
      return Object.assign({}, state, { cashflowPromo: action.payload });
    }
    case "SET_CREDITMANAGEMENT_PROMO": {
      return Object.assign({}, state, {
        creditManagementPromo: action.payload,
      });
    }
    case "SET_SUBSCRIPTIONMANAGEMENT_PROMO": {
      return Object.assign({}, state, {
        subscriptionManagementPromo: action.payload,
      });
    }
    case "LOAD_PROMO_SETUP": {
      return Object.assign({}, state, { promoSetup: action.payload });
    }
    case "SET_SETUP_PROMO": {
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
        },
        promoSetup: action.payload,
      });
    }
    case "SET_ASSESSMENT_PROMO" : {
      return Object.assign({}, state, { assessmentPromo: action.payload });
    }
    case "SET_CONTROL_STATUS_IMPRESSION_DIALOG": {
      const stage =
        action.payload === "Testing" &&
        state.controlImpressionDialog[state.controlImpressionDialog.length - 1] === "New"
          ? [
              ...state.controlImpressionDialog.slice(
                0,
                state.controlImpressionDialog.length - 1
              ),
              "Update",
              action.payload,
            ]
          : [...state.controlImpressionDialog, action.payload];
      return Object.assign({}, state, {
        controlImpressionDialog: stage,
      });
    }
    case "OPEN_NEW_IMPRESSION_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlImpressionDialog.slice(
          0,
          state.controlImpressionDialog.length - 1
        ); //remove last element
        return Object.assign({}, state, {
          dialogOpenImpression: action.payload,
          controlImpressionDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenImpression: action.payload });
     }
    //  case "OPEN_NEW_IMPRESSION_DIALOG":
    //   return Object.assign({}, state, { dialogOpenImpression: action.payload });
    case "CLEAR_IMPRESSION": {
      const tmp = Object.assign({}, state, {
        impressionPlaceholder: "",
        impressionResult: "",
        problemListValue: "",
        newImpression: {
          ...state.newImpression,
          id: 0,
          CompanyId: 0,
          shortname: "",
          checkbox_options: "",
          problem: 0
        },
      });
      return tmp;
    }
    case "TAKE_ITEM_IMPRESSION":
      return Object.assign({}, state, {
        impressionResult: action.payload.checkbox_options,
        newImpression: {
          ...state.newImpression,
          id: action.payload.id,
          CompanyId: action.payload.CompanyId,
          shortname: action.payload.shortname,
          checkbox_options: action.payload.checkbox_options,
          problem: action.payload.problem
        },
      });
    case "NEW_IMPRESSION_SHORTNAME_INPUT_CHANGE":
      return Object.assign({}, state, {
        newImpression: { ...state.newImpression, shortname: action.payload },
      });
    case "LOAD_IMPRESSION1_ALL":
      return Object.assign({}, state, {
        setupImpressionArrAll: action.payload,
      });
    case "SET_PLACEHOLDER_TO_IMPRESSION": {
      return Object.assign({}, state, { impressionResult: action.payload });
    }
    case "SET_IMPRESSION_RESULT_POSITION": {
      return Object.assign({}, state, { impressionResultPos: action.payload });
    }
    case "SET_IMPRESSION_RESULT": {
      // console.log("state.ruleResult=",state.ruleResult);
      // return Object.assign({}, state, { ruleResult: action.payload.result, ruleResultPos: action.payload.position});
      return Object.assign({}, state, {
        impressionResult: action.payload,
        newImpression: {
        ...state.newImpression,
        checkbox_options: action.payload,
      } });
    }
    case "NEW_IMPRESSION_CHECKBOXOPTIONS_INPUT_CHANGE":
      return Object.assign({}, state, {
        newImpression: {
          ...state.newImpression,
          checkbox_options: action.payload,
        },
      });
    case "SET_IMPRESSION_PLACEHOLDER_SECTION" : {
      return Object.assign({}, state, { impressionPlaceholder: action.payload });
    }
    case "SET_PLACEHOLDER_TO_RESULT_IMPRESSION": {
      return Object.assign({}, state, { impressionResult: action.payload });
    }
    case "SET_HASHCODE": {
      // return Object.assign({}, state, { hashCode: action.payload });
      return Object.assign({}, state, {
        activationInfo: {
        ...state.activationInfo,
        email: action.payload.email,
        hashCode: action.payload.hashCode,
        password: action.payload.password,
      } });
    }
    case "SHOW_PASSWORD_CHECKBOX_CHANGE" : {
      objectTmp = state.showPassword;
      valueTmp = objectTmp.value;
      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      return Object.assign({}, state, {
        showPassword: {
          ...state.showPassword,
          value: valueTmp.map((el) => el),
        },
      });
    }
    case "SET_SHOW_PASSWORD_CHECKBOX_CHANGE" : {
      objectTmp = state.showPassword;
      valueTmp = objectTmp.value;
      // console.log("valueTmp=",valueTmp);
      // return state
      return Object.assign({}, state, {
        showPassword: {
          ...state.showPassword,
          value: valueTmp.map((el) => false),
        },
      });
    }
    case "OPEN_HISTORY": {
      return Object.assign({}, state, { historyConfirm: action.payload });
    }
    case "SET_PROGRESS_UPGRADE": {
      return Object.assign({}, state, { progressUpgrade: action.payload });
    }
//========================================================================================
/*                                                                                      *
 *                                     TOURS EVENTS                                     *
 *                                                                                      */
//========================================================================================

    case "CHANGE_CHECK_TOUR": {
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, showtour: state.setupUser.showtour === 0 ? 1: 0 },
        showButtonChange: true
      });
    }
    case "SET_SHOW_TOUR": {
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, showtour: action.payload },
        showButtonChange: true
      });
    }
    case "CHANGE_CHECK_GETTINGSTARTED": {
      return Object.assign({}, state, {
        setupUser: { ...state.setupUser, showgettingstarted: state.setupUser.showgettingstarted === 0 ? 1: 0 },
        showButtonChange: true
      });
    }
    case "OPEN_TITLE_TOUR": {
      return Object.assign({}, state, { titleTour: action.payload });
    }
    case "GETSTARTED_TOUR": {
      return Object.assign({}, state, { getStartedTour: action.payload });
    }
    case "TITLE_TOUR_START_ON_MY_ASSESSMENT": {
      return Object.assign({}, state, { titleTourStartOnMyAssessment: action.payload });
    }
    case "INIT_MYASSESSMENT1" : {
      return Object.assign({}, state, {
        myAssessmentArr: initialState.myAssessmentArr,
        // totalMyAssessments: initialState.totalMyAssessments,
        // currentPageMyAssessment:initialState.currentPageMyAssessment,
        // currentPageMyAssessment:initialState.currentPageMyAssessment
      });
    }
    case "NEW_ASSESSMENT_STEP_OF_TOUR": {
      return Object.assign({}, state, { newAssessmentStepOfTour: action.payload });
    }
    case "SET_PASSWORD_INPUT_CHANGE":
      return Object.assign({}, state, {
        passwordForReset: { ...state.passwordForReset, password: action.payload },
      });
    case "SET_CONFIRM_INPUT_CHANGE":
      return Object.assign({}, state, {
        passwordForReset: { ...state.passwordForReset, confirm: action.payload },
      });
    case "CLEAR_PASSWORD_RESET_USER_DIALOG":
      return Object.assign({}, state, {
        passwordForReset: {
          ...state.passwordForReset,
          password: "",
          confirm : ""
        },
      });
    case "METHOD_PASSWORD_INPUT_CHANGE":
      return Object.assign({}, state,
       { methodChangingOfPassword: action.payload },
      );
    case "SET_MINIMIZE_SIDEBAR":
       return Object.assign({}, state, { minimizeCSidebar: action.payload });
    case "SET_RULE_ADULT_TYPE" :
      return Object.assign({}, state, {
        newRule: { ...state.newRule, adult_type: action.payload },
      });
    case "SET_RULE_ASSESSMENT_JSON" :
      if(action.payload==="Adolescent"){
        // console.log("adolescentPattern=",adolescentPattern);
        const tmp = Object.assign([], {
          ...adolescentPattern.map((el) =>
            el.topic !== undefined
            ? {
              ...el,
              body: el.body.map(el1 =>{
                switch(el1.shortname){
                  case "startblockalcohol" :
                    return [el1].concat(blockAlcohol);
                  case "startblockcannabis" :
                    return [el1].concat(blockCannabis);
                  case "startblockcocaine" :
                    return [el1].concat(blockCocaine);
                  case "startblockcrack" :
                    return [el1].concat(blockCrack);
                  case "startblockcrank" :
                    return [el1].concat(blockCrank);
                  case "startblockecstasy" :
                    return [el1].concat(blockEcstasy);
                  case "startblockhallucinogens" :
                    return [el1].concat(blockHallucinogens);
                  case "startblockheroin" :
                    return [el1].concat(blockHeroin);
                  case "startblockinhalants" :
                    return [el1].concat(blockInhalants);
                  case "startblockmeth" :
                    return [el1].concat(blockMeth);
                  case "startblockmethadone" :
                    return [el1].concat(blockMethadone);
                  case "startblockopiates" :
                    return [el1].concat(blockOpiates);
                  case "startblocksedative" :
                    return [el1].concat(blockSedative);
                  case "startblocktobacco" :
                    return [el1].concat(blockTobacco);
                  case "startblockother" :
                    return [el1].concat(blockOther);
                  default:
                      return el1;
                }
              }).flat()
            }:el
          ),
        })
        return Object.assign({}, state, { ruleAssessmentJson: tmp });
      } else {
        if(action.payload==="Adult"){
          const tmp = Object.assign([], {
            ...adultPattern.map((el) =>
              el.topic !== undefined
              ? {
                ...el,
                body: el.body.map(el1 =>{
                  switch(el1.shortname){
                    case "startblockalcohol" :
                      return [el1].concat(blockAlcohol);
                    case "startblockcannabis" :
                      return [el1].concat(blockCannabis);
                    case "startblockcocaine" :
                      return [el1].concat(blockCocaine);
                    case "startblockcrack" :
                      return [el1].concat(blockCrack);
                    case "startblockcrank" :
                      return [el1].concat(blockCrank);
                    case "startblockecstasy" :
                      return [el1].concat(blockEcstasy);
                    case "startblockhallucinogens" :
                      return [el1].concat(blockHallucinogens);
                    case "startblockheroin" :
                      return [el1].concat(blockHeroin);
                    case "startblockinhalants" :
                      return [el1].concat(blockInhalants);
                    case "startblockmeth" :
                      return [el1].concat(blockMeth);
                    case "startblockmethadone" :
                      return [el1].concat(blockMethadone);
                    case "startblockopiates" :
                      return [el1].concat(blockOpiates);
                    case "startblocksedative" :
                      return [el1].concat(blockSedative);
                    case "startblocktobacco" :
                      return [el1].concat(blockTobacco);
                    case "startblockother" :
                      return [el1].concat(blockOther);
                    default:
                        return el1;
                  }
                }).flat()
              }:el
            ),
          })
          return Object.assign({}, state, { ruleAssessmentJson: tmp });
          // return Object.assign({}, state, { ruleAssessmentJson: adultPattern });
        } else {
          const tmp = Object.assign([], {
            ...adolescentPattern.map((el) =>
              el.topic !== undefined
              ? {
                ...el,
                body: el.body.map(el1 =>{
                  switch(el1.shortname){
                    case "startblockalcohol" :
                      return [el1].concat(blockAlcohol);
                    case "startblockcannabis" :
                      return [el1].concat(blockCannabis);
                    case "startblockcocaine" :
                      return [el1].concat(blockCocaine);
                    case "startblockcrack" :
                      return [el1].concat(blockCrack);
                    case "startblockcrank" :
                      return [el1].concat(blockCrank);
                    case "startblockecstasy" :
                      return [el1].concat(blockEcstasy);
                    case "startblockhallucinogens" :
                      return [el1].concat(blockHallucinogens);
                    case "startblockheroin" :
                      return [el1].concat(blockHeroin);
                    case "startblockinhalants" :
                      return [el1].concat(blockInhalants);
                    case "startblockmeth" :
                      return [el1].concat(blockMeth);
                    case "startblockmethadone" :
                      return [el1].concat(blockMethadone);
                    case "startblockopiates" :
                      return [el1].concat(blockOpiates);
                    case "startblocksedative" :
                      return [el1].concat(blockSedative);
                    case "startblocktobacco" :
                      return [el1].concat(blockTobacco);
                    case "startblockother" :
                      return [el1].concat(blockOther);
                    default:
                        return el1;
                  }
                }).flat()
              }:el
            ),
          })
          return Object.assign({}, state, { ruleAssessmentJson: tmp });
        }
      }
    // case "SET_ADULT_TYPE"  :
    // if(action.payload==="Recommendations"){
    //   return Object.assign({}, state, { ruleAdultType: "All"});
    // } else {
    //   if(action.payload==="Recommendations Adolescent"){
    //     return Object.assign({}, state, { ruleAdultType: "Adolescent"});
    //   } else {
    //     return Object.assign({}, state, { ruleAdultType: "Adult"});
    //   }
    // }
    case "SEARCH_RULE_ADULT_TYPE" :
      return Object.assign({}, state, { ruleAdultType: action.payload});
    case "SEARCH_ASSESSMENT_ADULT_TYPE" :
      return Object.assign({}, state, { assessmentAdultType: action.payload});
    case "SET_LOAD_DEFAULT_IMPRESSION" :
      return Object.assign({}, state, { loadedDefaultImpressions: action.payload});
    case "SHOW_LEFT_PANEL" :
      return Object.assign({}, state, { showLeftSections: action.payload});
    case "SET_TOGGLE_PREVIEW" :
      return Object.assign({}, state, { dropdownPreviewOpen: !state.dropdownPreviewOpen});
    case "SET_MODAL_PROGRESS": {
      return Object.assign({}, state, { modalProgress: action.payload });
    }
    case "SET_MODAL_PROGRESS_INDEX": {
      return Object.assign({}, state, { modalProgressIndex: action.payload });
    }
    case "VIEW_INDEX_BOOKMARK": {
      return Object.assign({}, state, { pageSelection: action.payload });
    }
    case "SET_USER_SUBSCRIPTIONS_ARRAY": {
      // console.log("action.payload=",action.payload)
      return Object.assign({}, state, { userSubscriptionsArr: action.payload });
    }
    case "NEED_REFRESH_SUBSCRIPTIONS": {
      return Object.assign({}, state, {needRefreshSubscriptions:!state.needRefreshSubscriptions,});
    }
    case "OPEN_MODAL_RETURNTOHAF": {
      return Object.assign({}, state, { modalReturnToHaf: action.payload });
    }
    case "SET_OWNER_SUBSCRIPTIONS_ARRAY": {
      return Object.assign({}, state, { changeOwnerSubscriptionArr: action.payload });
    }
    case "OPEN_MODAL_SHOW_COMPANY": {
      return Object.assign({}, state, { showCompany: action.payload });
    }
    case "CHOOSED_FOR_SHOW_COMPANY": {
      return Object.assign({}, state, { choosedForShowCompany: action.payload });
    }
    case "SET_ACTIVE_CASHFLOW_TAB": {
      return Object.assign({}, state, { cashflowActiveTab: action.payload });
    }
    case "PUT_JSON_FOR_PREVIEW": {
      return Object.assign({}, state, { jsonForModalPreview: action.payload });
    }
    case "MAKE_ARRAY_OF_SECTIONS_FOR_PREVIEW": {
      // let tmp_state0 = Object.assign({}, state, {
      //   jsonForModalPreview: [{id:0, section:"TOPIC", topic: "Personal Information"}]
      // })
      // console.log("action.payload=",action.payload,"state.assessment_state=",state.assessment_state)
      let tmp_state = Object.assign({}, state, {
        arrayOfSectoonsForPreview: [{id:0, section:"TITLE", topic: "Personal Information", checked: "true", description:""}].concat(
          //   action.payload.map((el,index)=>{
          //     return {id:index+1, section:el.section, topic: el.topic, checked: el.checked, description: el.description }
          // })
          action.payload.original.filter((el)=>{
            if(el.checked==="true" && el.section!=="SUG"){
              if(!(el.description==="(optional)" && compareJsonSection(action.payload.original,action.payload.pattern,el.section))) {
                return {section:el.section, topic: el.topic, checked: el.checked, description: el.description }
              }
            }
        }).map((el,index)=>{
          return {id:index+1,...el}
        })
        )
      });
      let tmp_state1 = Object.assign({}, tmp_state, {arrayOfSectoonsForPreview: [...tmp_state.arrayOfSectoonsForPreview,{id:tmp_state.arrayOfSectoonsForPreview.length, section:"ALL", topic: "Full Report", checked: "true", description:""}]})
      let tmp_state2 = Object.assign({}, tmp_state1, {arrayOfSectoonsForPreview: tmp_state1.arrayOfSectoonsForPreview.map(el => {
        return {...el, display: sectionsDeskToDisplay.find(el1=> el.section===el1.section).display}
      })})

      // ADD TRETMENT Plan
      let tmp_state3 = Object.assign({}, tmp_state2, {arrayOfSectoonsForPreview: [...tmp_state2.arrayOfSectoonsForPreview,{id:tmp_state2.arrayOfSectoonsForPreview.length, section:"TP", topic: "Treatment Plan", checked: "true", description:"", display:'Treatment Plan'}]})
      return tmp_state2;
    }
    case "ADD_TREATMENT_PLAN_TO_ARRAY_OF_SECTIONS_FOR_PREVIEW": {
      let tmp_state1 = Object.assign({}, state, {arrayOfSectoonsForPreview: [{id:state.arrayOfSectoonsForPreview.length, section:"TP", topic: "Treatment Plan", checked: "true", description:"", display:'Treatment Plan'}]})
      // console.log("tmp_state1.arrayOfSectoonsForPreview=",tmp_state1.arrayOfSectoonsForPreview)
      // let tmp_state2 = Object.assign({}, tmp_state1, {arrayOfSectoonsForPreview: tmp_state1.arrayOfSectoonsForPreview.map(el => {
      //   return {...el, display: sectionsDeskToDisplay.find(el1=> el1.section==="TP").display}
      // })})
      return tmp_state1;
    }
    case "SET_CURRENT_SECTOON_FOR_PREVIEW": {
      return Object.assign({}, state, { currentSectoonForPreview: action.payload });
    }
    case "SET_ACTIVE_PREVIEW_TAB": {
      return Object.assign({}, state, { previewActiveTab: action.payload });
    }
    case "OPEN_MODAL_REFERRAL": {
      return Object.assign({}, state, { modalReferral: action.payload });
    }
    case "WHAT_REFERRAL_PAYMENT": {
      return Object.assign({}, state, { whatReferralPayment: action.payload });
    }
    case "OPEN_MODAL_START_STRIPE": {
      return Object.assign({}, state, { modalStartStripe: action.payload });
    }
    // case "CHECK_REFERRALS": {
    //   return Object.assign({}, state, { checkReferrals: action.payload });
    // }
    case "OPEN_NEW_PRODUCT_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlProductDialog.slice(
          0,
          state.controlProductDialog.length - 1
        ); //remove last element
        return Object.assign({}, state, {
          dialogOpenProduct: action.payload,
          controlProductDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenProduct: action.payload });
      }
    case "SET_CONTROL_STATUS_PRODUCT_DIALOG": {
      const stage =
        action.payload === "Testing" &&
        state.controlProductDialog[state.controlProductDialog.length - 1] === "New"
          ? [
              ...state.controlProductDialog.slice(
                0,
                state.controlProductDialog.length - 1
              ),
              "Update",
              action.payload,
            ]
          : [...state.controlProductDialog, action.payload];
      return Object.assign({}, state, {
        controlProductDialog: stage,
      });
    }
// PRODUCT
  case "REGISTR_PRODUCT_INPUT_CHANGE":
    return Object.assign({}, state, { productRegistr: action.payload });
  // case "OPEN_NEW_PRODUCT_DIALOG":
  //   if (action.payload === false) {
  //     const tmp = state.controlProductDialog.slice(
  //       0,
  //       state.controlProductDialog.length - 1
  //     ); //remove last element
  //     return Object.assign({}, state, {
  //       dialogOpenProduct: action.payload,
  //       controlProductDialog: tmp,
  //     });
  //   } else {
  //     return Object.assign({}, state, { dialogOpenProduct: action.payload });
  //   }
    // case "SET_CONTROL_STATUS_PRODUCT_DIALOG": {
    //   const stage =
    //     action.payload === "Testing" &&
    //     state.controlProductDialog[state.controlProductDialog.length - 1] === "New"
    //       ? [
    //           ...state.controlProductDialog.slice(
    //             0,
    //             state.controlProductDialog.length - 1
    //           ),
    //           "Update",
    //           action.payload,
    //         ]
    //       : [...state.controlProductDialog, action.payload];
    //   return Object.assign({}, state, {
    //     controlProductDialog: stage,
    //   });
    // }
    case "CLEAR_PRODUCT": {
      return Object.assign({}, state, {
        newProduct: {
          id: 0,
          stripe_price_id: "",
          product_date: "",
          product: "",
          credit: 0,
          cost: 0.0,
          type_system: "HAF Adult",
          type_subscription : "Limited",
          status: ""
        },
        // productExpiresFilter: {
        //   name: "Unlimited :",
        //   prevalue: "Unlimited :",
        //   disabled: "false",
        //   type: "checkbox",
        //   value: [false],
        //   radio_options: ["Unlimited"],
        // },
      });
    }
    case "SET_PRODUCT_STRIPE_PRICE_ID": {
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          stripe_price_id: action.payload,
        },
      });
    }
    case "SET_PRODUCT_PRODUCT":
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          product: action.payload,
        },
      });
    case "SET_PRODUCT_CREDIT":
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          credit: action.payload,
        },
      });
    case "SET_PRODUCT_COST" :
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          cost: action.payload,
        },
      });
    case "SET_PRODUCT_TYPE_SYSTEM" :
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          type_system: action.payload,
        },
      });
    case "SET_PRODUCT_TYPE_OF_SUBSCRIPTION" :
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          type_subscription: action.payload,
          credit: action.payload==="Unlimited"?0:state.newProduct.credit
        },
      });
    case "SET_PRODUCT_CREATED":
      // console.log(action.payload);
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          created: action.payload,
        },
      });
    case "SET_PRODUCT_EXPIRES":
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          expires: action.payload,
        },
      });
    case "LOAD_PRODUCTS1": {
      // console.log("action.payload=",action.payload);
      return Object.assign({}, state, { productListArr: action.payload });
    }
    case "LOAD_PRODUCTS_ALL_COUNT": {
      return Object.assign({}, state, { totalProducts: action.payload });
    }
    case "LOAD_PAGE_PRODUCT_COUNT": {
      return Object.assign({}, state, { pagesCountProduct: action.payload });
    }
    case "SET_CURRENT_PRODUCT_PAGE": {
      return Object.assign({}, state, { currentPageProduct: action.payload });
    }
    case "INIT_PRODUCT_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesProduct,
        state.pagesCountProduct
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesProduct: arr });
    }

    case "SET_VIS_PRODUCT_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesProduct,
        state.pagesCountProduct
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountProduct - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesRule: arr });
    }
    case "NEED_REFRESH_PRODUCT_LIST": {
      return Object.assign({}, state, {
        needRefresProductList: !state.needRefresProductList,
      });
    }
    case "TAKE_ITEM_PRODUCT":
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          id: action.payload.id,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          stripe_price_id: action.payload.stripe_price_id,
          product: action.payload.product,
          credit: action.payload.credit,
          cost: action.payload.cost,
          type_system: action.payload.type_system,
          type_subscription: action.payload.type_subscription,
        },
        productExpiresFilter: {
          ...state.productExpiresFilter,
          value:
            moment(action.payload.expires).format("YYYY-MM-DD") === "1970-01-01"
              ? [true]
              : [false],
        },
      });
    case "SET_EXPIRES_PRODUCT": {
      return Object.assign({}, state, {
        newProduct: {
          ...state.newProduct,
          expires: moment().startOf("day").format("YYYY-MM-DD"),
          status: action.payload.status,
        },
        productExpiresFilter: {
          ...state.productExpiresFilter,
          value: [false],
        },
      });
    }
    case "PRODUCTEXPIRES_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.productExpiresFilter;
      valueTmp = objectTmp.value;

      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      let tempArr = [];
      objectTmp.value.forEach((el, index) => {
        if (el === true) tempArr.push(objectTmp.radio_options[index]);
      });

      // console.log("tempArr=",tempArr)
      return Object.assign({}, state, {
        productExpiresFilter: {
          ...state.productExpiresFilter,
          value: valueTmp.map((el) => el),
        },
        newProduct: {
          ...state.newProduct,
          expires: moment(action.payload.expires).format("YYYY-MM-DD"),
          expires: tempArr.includes("Unlimited")
            ? "Unlimited"
            : moment().add(1, "year").startOf("hour").format("YYYY-MM-DD"),
        },
      });
    }
    case "CONFIRM_PRODUCT_YESNO": {
      return Object.assign({}, state, { confimProductYesNo: action.payload });
    }
    case "SET_FIND_MONTH_PRODUCT_PERF": {
      return Object.assign({}, state, { findMonthProductPerf: action.payload });
    }
    case "SET_FIND_YEAR_PRODUCT_PERF": {
      return Object.assign({}, state, { findYearProductPerf: action.payload });
    }
    case "SET_CASHFLOW_PRODUCT": {
      return Object.assign({}, state, { cashflowProduct: action.payload });
    }
    case "SET_CREDITMANAGEMENT_PRODUCT": {
      return Object.assign({}, state, {
        creditManagementProduct: action.payload,
      });
    }
    case "LOAD_PRODUCT_SETUP": {
      return Object.assign({}, state, { productSetup: action.payload });
    }
    case "SET_SETUP_PRODUCT": {
      return Object.assign({}, state, {
        setupOrg: {
          ...state.setupOrg,
          changed: true,
        },
        productSetup: action.payload,
      });
    }
    case "SET_ASSESSMENT_PRODUCT" : {
      return Object.assign({}, state, { assessmentProduct: action.payload });
    }
    case "SET_CHOOSED_STRIPE_PRICE_ID" : {
      return Object.assign({}, state, { choosed_stripe_price_id: action.payload });
    }
    case "OPEN_ADD_CONTRACT_DIALOG":
      return Object.assign({}, state, { dialogAddContrsctOpen: action.payload });
    case "OPEN_NEW_CONTRACT_DIALOG": {
      if (action.payload === false) {
        const tmp = state.controlContractDialog.slice(
          0,
          state.controlContractDialog.length - 1
        ); //remove last element
        return Object.assign({}, state, {
          dialogOpenContract: action.payload,
          controlContractDialog: tmp,
        });
      } else {
        return Object.assign({}, state, { dialogOpenContract: action.payload });
      }
    }
    case "LOAD_ORGANIZATION_ARRAY" : {
      return Object.assign({}, state, { organizationArray: [{
        UserId: 0,
        CompanyId: 0,
        firstname: "",
        lastname: "",
        email: "",
        created: "",
        role: "",
        organization: "",
        status: ""
    }, ...action.payload] });
    }
    case "SET_CONTRACT_ORGANIZATION" : {
      console.log("action.payload=",action.payload);
      const tmp =  Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          CompanyContractId: action.payload.CompanyContractId,
          organization: action.payload.organization,
        },
      });
      // console.log("tmp=",tmp)
      return tmp;
    }
    case "SET_CONTRACT_CONTRACT_ID" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          contract_id: action.payload
        },
      });
    }
    case "SET_CONTRACT_PAYMENTTERM" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          payment_term: action.payload
        },
      });
    }
    case "SET_CONTRACT_STATUS" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          status_contract: action.payload
        },
      });
    }
    case "SET_DATE_OF_START_CONTRACT" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          date_of_start_contract: action.payload
        },
      });
    }
    case "SET_DATE_OF_END_CONTRACT" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          date_of_end_contract: action.payload
        },
      });
    }
    case "SET_DATE_OF_LAST_INVOICE" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          date_of_last_invoice: action.payload
        },
      });
    }
    case "SET_AMOUNT_OF_LAST_INVOICE" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          amount_of_last_invoice: action.payload
        },
      });
    }
    case "SET_CONTRACT_AMOUNT" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          amount: action.payload
        },
      });
    }
    case "SET_CONTRACT_CREDITS" : {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          credits: action.payload
        },
      });
    }
    // case "SET_CONTRACT_TYPE_SYSTEM" : {
    //   return Object.assign({}, state, {
    //     newContract: {
    //       ...state.newContract,
    //       type_system: action.payload
    //     },
    //   });
    // }
    // case "SET_CONTROL_STATUS_CONTRACT_DIALOG": {
    //   const stage =
    //     action.payload === "Testing" &&
    //     state.controlContractDialog[state.controlContractDialog.length - 1] === "New"
    //       ? [
    //           ...state.controlContractDialog.slice(
    //             0,
    //             state.controlContractDialog.length - 1
    //           ),
    //           "Update",
    //           action.payload,
    //         ]
    //       : [...state.controlContractDialog, action.payload];
    //   return Object.assign({}, state, {
    //     controlContractDialog: stage,
    //   });
    // }
    case "SET_CONTROL_STATUS_CONTRACT_DIALOG": {
      const stage = [...state.controlContractDialog, action.payload];
      // console.log("stage=",stage);
      return Object.assign({}, state, {controlContractDialog: stage});
    }
    case "CLEAR_CONTRACT": {
      return Object.assign({}, state, {
        contractResult: "",
        newContract: {
          id:0,
          CompanyId: 0,
          UserId: 0,
          CompanyContractId: 0,
          contract_id: "",
          contract_description: "",
          organization: "",
          date_of_start_contract: "",
          date_of_end_contract: "",
          date_of_last_invoice: "",
          amount_of_last_invoice: "",
          payment_term: "",
          credits: "",
          amount: "",
          // type_system: "HAF",
          produuct_line_haf: {name:"HAF",status: false},
          produuct_line_asi: {name:"ASI",status: false},
          status_contract: ""
        }
      });
    }
    case "CLEAR_CONTRACT_INVOICE": {
      return Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          amount_of_last_invoice: "",
          credits: "",
        }
      });
    }
    // case "SET_CONTRACT_PRODUCT_LINE" : {
    //   valueTmp=state.type_system.map(el=>el.status);
    //   if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
    //   else valueTmp[action.payload] = true;
    //   let valueTmp1 = valueTmp.map((el, index) => {
    //     if (el === true) return state.type_system.map(el=>el.name)[index];
    //     else return null;
    //   });
    //   console.log("valueTmp1=",valueTmp1);

    //   let tempArr = [];
    //   state.type_system.map(el=>el.status).forEach((el, index) => {
    //     if (el === true) tempArr.push(state.type_system.map(el=>el.name)[index]);
    //   });
    //   return Object.assign({}, state, {
    //     newContract: {
    //       ...state.newContract,
    //       produuct_line_haf: tempArr.includes("HAF") ? true : false,
    //       produuct_line_asi: tempArr.includes("ASI") ? true : false,
    //     },
    //   });
    // }
    case "SET_CONTRACT_PRODUCT_LINE" : {
        let tmpState = Object.assign({}, state, {
        newContract: {
          ...state.newContract,
          produuct_line_haf: {name:"HAF",status: action.payload==0?!state.newContract.produuct_line_haf.status:state.newContract.produuct_line_haf.status},
          produuct_line_asi: {name:"ASI",status: action.payload==1?!state.newContract.produuct_line_asi.status:state.newContract.produuct_line_asi.status},
        },
      });
      // console.log("tmpState.newContract=",tmpState.newContract)
      let tmpState1 =  Object.assign({}, tmpState, {
        newContract: {
          ...tmpState.newContract,
          credits: tmpState.newContract.produuct_line_haf.status===false?0:tmpState.newContract.credits
        },
      });
      // console.log("tmpState1.newContract=",tmpState1.newContract)
      return tmpState1;
    }
    case "SET_PLACEHOLDER_TO_CONTRACT": {
      return Object.assign({}, state, { contractResult: action.payload });
    }
    case "SET_CONTRACT_RESULT": {
      // console.log("state.ruleResult=",state.ruleResult);
      // return Object.assign({}, state, { ruleResult: action.payload.result, ruleResultPos: action.payload.position});
      return Object.assign({}, state, {
        contractResult: action.payload,
        newContract: {
        ...state.newContract,
        contract_description: action.payload,
      } });
    }
    case "SET_CONTRACT_RESULT_POSITION": {
      // console.log("state.ruleResult=",state.ruleResult);
      // return Object.assign({}, state, { ruleResult: action.payload.result, ruleResultPos: action.payload.position});
      return Object.assign({}, state, { contractResultPos: action.payload });
    }
    case "SET_CONTRACTMANAGEMENT_PROMO": {
      return Object.assign({}, state, {
        contractManagementPromo: action.payload,
      });
    }
    case "TAKE_ITEM_CONTRACT": {
      let tmpState = Object.assign({}, state, {
        contractResult: action.payload.contract_description,
        newContract: {
          ...state.newContract,
          id:action.payload.id,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          CompanyContractId: action.payload.CompanyContractId,
          contract_id: action.payload.contract_id,
          contract_description: action.payload.contract_description,
          organization: action.payload.organization,
          date_of_start_contract: moment(action.payload.date_of_start_contract).format("YYYY-MM-DD"),
          date_of_end_contract: moment(action.payload.date_of_end_contract).format("YYYY-MM-DD"),
          date_of_last_invoice: moment(action.payload.date_of_last_invoice).format("YYYY-MM-DD"),
          amount_of_last_invoice: parseFloat(action.payload.amount_of_last_invoice).toFixed(),
          payment_term: action.payload.payment_term,
          credits: parseFloat(action.payload.credits).toFixed(),
          amount: parseFloat(action.payload.amount).toFixed(),
          // type_system: "HAF",
          produuct_line_haf: {name:"HAF",status: action.payload.produuct_line_haf},
          produuct_line_asi: {name:"ASI",status: action.payload.produuct_line_asi},
          status_contract: action.payload.status_contract
        },
      });
      // console.log("tmpState=",tmpState);
      return tmpState;
    }
    case "SET_USER_CONTRACTS_ARRAY": {
      // console.log("action.payload=",action.payload)
      return Object.assign({}, state, { userContractsArr: action.payload });
    }
    case "OPEN_PAYMENT_HISTORY": {
      return Object.assign({}, state, { openPaymentHistory: action.payload });
    }
    case "LOAD_BILLING_ARR": {
      return Object.assign({}, state, { setupBillingArr: action.payload });
    }
    case "CHANGE_CHECK_SMSNOTE": {
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, sms_note: state.setupOrg.sms_note === 0 ? 1: 0 }});
    }
    case "CHANGE_CHECK_SMSNOTE_PREFERENCES": {
      return Object.assign({}, state, {
        setupOrg: { ...state.setupOrg, sms_note: state.setupOrg.sms_note === 0 ? 1: 0 },
        showButtonChange: true
      });
    }
    case "SMS_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.smsFilter;
      valueTmp = objectTmp.value;
      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      return Object.assign({}, state, {
        smsFilter: {
          ...state.smsFilter,
          value: valueTmp.map((el) => el),
        },
      });
    }

    case "SET_TREATMENTPLAN_RESULT_PRESET": {
      // console.log("SET_TREATMENTPLAN_RESULT_PRESET");
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository, preset: action.payload, presetORpostsetChanged: true }, ///+
      });
    }
    case "SET_TREATMENTPLAN_RESULT_POSTSET": {
      // console.log("SET_TREATMENTPLAN_RESULT_POSTSET");
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository,  postset: action.payload, presetORpostsetChanged: true}, ///+
      });
    }

    case "SET_INIT_TREATMENTPLAN_PRESET_POSTSET": {
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository, preset: action.payload.preset, postset: action.payload.postset, presetORpostsetChanged: false} , ///+
      });
    }


    case "SET_SOURCE_FILE_TO_UPLOAD": {
      return Object.assign({}, state, {sourceFileToUpload: action.payload,});
    }
    case "LOAD_ARRAY_OF_FILES" : {
      return Object.assign({}, state, {arrayOfFiles: action.payload,});
    }
    case "LOAD_ARRAY_OF_FILES1" : {
      return Object.assign({}, state, {arrayOfFiles1: action.payload,});
    }
     case "NEED_REFRES_LIST_OF_FILES": {
      return Object.assign({}, state, {needRefresListOfFiles: !state.needRefresListOfFiles,});
    }
    case "SET_DELETED_FILE": {
      return Object.assign({}, state, {fileForDelete: action.payload,});
    }
    case "LOAD_PROBLEM_LIST": {
      return Object.assign({}, state, { problemListArr: action.payload });
    }
    case "NEW_IMPRESSION_PROBLEM_LIST_INPUT_CHANGE":
      return Object.assign({}, state, {
        newImpression: { ...state.newImpression, problem: action.payload },
    });
    case "LOAD_TREE_DATA":
      // return Object.assign({}, state, { treeData: action.payload }); ///-
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository, treeData: action.payload }, ///+
      });
    case "SET_TREE_TITLE" :
      return Object.assign({}, state, { testTitle: action.payload });
    case "LOAD_DEFAULT_TREE_DATA" :
      // const tmpProblems = action.payload.map(el=>el.title)
      const tmpTreeData = action.payload.map(el=>{
        return {
          id: el.id,
          level: "problem",
          title:el.title,
          isDirectory: true,
          expanded: true,
          type: "divison",
          children:[]}
      })
      // return Object.assign({}, state, { treeData: tmpTreeData }); ///-
      return Object.assign({}, state, {
        newRepository: { ...state.newRepository, treeData: tmpTreeData }, ///+
      });
    case "TITLE_IREE_INPUT_CHANGE" :
      // const tmpState = Object.assign({}, state, {
      //   treeData: putTitleInTree(state.treeData,action.payload.row.node.id,action.payload.target) ///-
      // })
      const tmpState = Object.assign({}, state, {
        newRepository: { ...state.newRepository, treeData: putTitleInTree(state.newRepository.treeData,action.payload.row.node.id,action.payload.target) }, ///+
      });
      return tmpState;
    case "CONFIRM_GOALOBJECTIVEFIELD": {
      return Object.assign({}, state, { confimField: action.payload });
    }
    case "GOALOBJECTIVEFIELD_INPUT_CHANGE":
      return Object.assign({}, state, { goalObjectiveField: action.payload });
    case "SET_ROWINFOTOEXTERNAL":
      return Object.assign({}, state, { rowInfo: action.payload });
    case "SET_GOAL_OR_OBJECTIVE":
      return Object.assign({}, state, { goal_OR_objective: action.payload });
    case "SET_ADD_OR_EDIT":
      return Object.assign({}, state, { add_OR_edit: action.payload });
    case "CONFIRM_YESNO_NODE": {
      return Object.assign({}, state, { confimYesNoNode: action.payload });
    }
    case "SET_ACTIVE_DEFAULT_SETUP_TAB": {
      return Object.assign({}, state, { defaultSetupActiveTab: action.payload });
    }
    case "SET_DEFAULT_SETUP_USER": {
      return Object.assign({}, state, { defaultSetupUser: action.payload});
    }
    case "CONFIRM_SELECTCOMPANY": {
      return Object.assign({}, state, { confirmSelectCompany: action.payload});
    }
    case "SETUP_SELECT_COMPANY": {
      return Object.assign({}, state, { selectedCompany: {CompanyId: action.payload.CompanyId, organization:action.payload.organization}});
    }
    case "SET_SEARCH_SELECT_COMPANY": {
      return Object.assign({}, state, { searchSelectCompany: action.payload });
    }
    case "ALLOW_TREATMENT_PLAN_FOR_COMPANY": {
      return Object.assign({}, state, { allowTreatmentPlanForCompany: action.payload });
    }
    case "SET_PROPAMANAGEMENT_PROMO": {
      return Object.assign({}, state, {
        propaManagementPromo: action.payload,
      });
    }
    case "LOAD_COMPANIES1_FOR_IMPRESSION": {
      return Object.assign({}, state, { setupCompanyArrForImpression: action.payload });
    }
    case "OPEN_LEFT_WINDOW_ADVISOR": {
      return Object.assign({}, state, { openLeftWindowAdvisor: action.payload });
    }
    case "OUTPUT_ADVISOR_ADVICE": {
      return Object.assign({}, state, { outputAdvisorAdvice: action.payload });
    }
    // case "CONFIRM_RECREATE_AI": {
    //   return Object.assign({}, state, { confimRecreateAi: action.payload });
    // }
    case "WAS_OLD_OUTPUT_ADVISOR_ADVICE" : {
      return Object.assign({}, state, { wasOldOutputAdvisorAdvice: action.payload });
    }
    default:
      return state;
  }
};

export default evtreducer;
